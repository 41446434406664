import classNames from "classnames"
import { useEffect, useState } from "react"

import Image from "./Image"

export default function HeroCarousel({
  images,
  timeBetweenSlides = 6000,
  onLoadingComplete,
}) {
  const [imagesStatus, setImagesStatus] = useState([...images].map(() => false))
  const [activeIndex, setActiveIndex] = useState(0)

  const transformHotspot = (hotspot) => {
    if (!hotspot) return

    const { x, y, width, height } = hotspot

    return `${(x + width / 2) * 100}% ${(y + height / 2) * 100}%`
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length)
    }, timeBetweenSlides)
    return () => clearInterval(interval)
  }, [images.length])

  useEffect(() => {
    const allImagesLoaded = imagesStatus.every((loaded) => loaded)
    if (allImagesLoaded) onLoadingComplete()
  }, [imagesStatus])

  return (images || []).map((image, imageIndex) => {
    const isPrevious = (activeIndex - 1) % images.length === imageIndex
    const isActive = imageIndex === activeIndex
    return (
      <Image
        key={image.asset._id}
        src={image.asset._id}
        aspect={0.5}
        onLoadingComplete={() =>
          setImagesStatus((imagesStatus) =>
            imagesStatus.map((loaded, index) =>
              index === imageIndex ? true : loaded
            )
          )
        }
        className={classNames("hero_carousel__slide", {
          "is-active": isActive,
        })}
        style={{
          zIndex: isActive ? 2 : isPrevious ? 1 : 0,
          transformOrigin: transformHotspot(image.hotspot),
          "--animation-delay-ms": `${timeBetweenSlides}ms`,
        }}
      />
    )
  })
}
