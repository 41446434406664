import NavLink from "./NavLink"
import { motion } from "framer-motion"
import { scrollRevealAnimation } from "../lib/animations"
import { locales } from "../locales.config.mjs"
import { useRouter } from "next/router"
import { useTranslations } from "lib/useTranslations.client"

export default function Footer({
  globalSettings: {
    footer: { title, contact, links = [] },
  },
  enabledLocales,
}) {
  const router = useRouter()
  const { pathname, asPath, query } = router
  const { t } = useTranslations()

  const onLocaleChange = (e) => {
    const nextLocale = e.target.value

    // change just the locale and maintain all other route information including href's query
    router.push({ pathname, query }, asPath, { locale: nextLocale })
  }

  return (
    <motion.footer className="bg-secondary" {...scrollRevealAnimation}>
      <div className="container site-grid">
        <div className="col-span-9 col-start-5 lg:col-span-6 lg:col-start-7">
          <p className="text-4xl md:text-5xl pt-10 pb-40">{title}</p>
        </div>
      </div>
      <div className="container site-grid text-md mb-20">
        <hr className="col-span-12 border-grayed-muted" />
        <div className="col-span-4 lg:col-span-6 pt-4">Contact</div>
        <div className="col-span-8 lg:col-span-6">
          {contact.map(({ _key, email, phone, offices }) => (
            <div
              className="site-grid first:border-t-0 border-t border-grayed-muted pt-4 mt-12 md:mt-0"
              key={_key}
            >
              <div className="col-span-6">
                {offices.map(({ _key, title, address }) => {
                  return (
                    <div className="mb-5" key={_key}>
                      <p>{title}</p>
                      <p className="text-grayed whitespace-pre-wrap">
                        {address}
                      </p>
                    </div>
                  )
                })}
              </div>
              <div className="col-span-6">
                <a href={`mailto:${email}`}>{email}</a>
                <br />
                <a href={`tel:${phone}`}>{phone}</a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="p-4 lg:px-8 md:pb-10 md:flex justify-between border-t border-grayed-muted">
        <ul className="md:flex gap-4 lg:gap-8 text-grayed">
          {links.map((link) => (
            <li key={link._key}>
              <NavLink link={link} />
            </li>
          ))}
          {enabledLocales.length > 1 && (
            <li>
              <select
                className="bg-transparent rounded-md"
                onChange={onLocaleChange}
                defaultValue=""
              >
                <option value="" disabled>
                  {t("general.change-language")}
                </option>
                {enabledLocales.map((key) => (
                  <option key={key} value={key}>
                    {locales[key]}
                  </option>
                ))}
              </select>
            </li>
          )}
        </ul>
        <p className="mt-4 md:mt-0 text-right">
          SolarLab © {new Date().getFullYear()}
        </p>
      </div>
    </motion.footer>
  )
}
