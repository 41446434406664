import kebabCase from "lodash.kebabcase"
import { useRouter } from "next/router"
import NavLink from "../components/NavLink"

export const modalPageTypes = [
  { type: "project", animateFrom: "right" },
  { type: "calculator", animateFrom: "bottom" },
]

// Custom helpers
export const path = (slug, hash) => {
  if (hash) return `/${slug}#${kebabCase(hash)}`
  return `/${slug}`
}

export const fullUrl = (path) => `https://solarlab.global${path}`
export const fullUrlWithLocale = (path, locale) => {
  const { defaultLocale } = useRouter()
  return locale === defaultLocale ? fullUrl(path) : `https://solarlab.global/${locale}${path}`
}

export const formatProjectType = (type) => {
  if (!type) return ""

  switch (type) {
    case "newbuild":
      return "New Build"
    case "refurbishment":
      return "Refurbishment"
    default:
      return ""
  }
}

export const getDimensions = (string) => {
  const dimensionString = string.split("-")[2]
  const [width, height] = dimensionString.split("x")

  return {
    width: Number(width),
    height: Number(height),
    aspectRatio: width / height,
  }
}

export function yearIsFirst(year, years) {
  const yearCentury = century(year.year)
  const index = years.indexOf(year)
  const prevYear = years?.[index - 1] || null

  if (prevYear && century(prevYear.year) == yearCentury) return false

  return true
}

export function onlyInCentury(year, years) {
  const yearCentury = century(year.year)
  const index = years.indexOf(year)
  const prevYear = years?.[index - 1] || null
  const nextYear = years?.[index + 1] || null

  if (prevYear && century(prevYear.year) == yearCentury) return false
  if (nextYear && century(nextYear.year) == yearCentury) return false

  return true
}

export function century(year) {
  const stringedYear = String(year)
  return `${stringedYear[0]}${stringedYear[1]}`
}

export function createCenturiesFromYears(rawYears) {
  const years = filterDrafts(rawYears)
  const yearMap = years
    .map((year) => ({ ...year, century: century(year.year) }))
    .reduce((allYears, currentYear) => {
      if (allYears[currentYear.century]) {
        allYears[currentYear.century].push(currentYear)
      } else {
        allYears[currentYear.century] = [currentYear]
      }

      return allYears
    }, {})

  const flattened = Object.entries(yearMap)
    .reduce((allItems, [century, years]) => {
      allItems.push({
        century,
        years,
      })

      return allItems
    }, [])
    .sort((a, b) => b.century - a.century)

  return flattened
}

/**
 * Filters out documents that have a draft version of them already
 *
 * @param {array} docs
 * @returns array of docs with no draft duplicates
 */
export function filterDrafts(docs = []) {
  // Add doc if it's a preview version
  // Add it only if there are no other draft (preview) versions of this document
  return docs.filter(
    (docA) => !docs.some((docB) => docB._id == `drafts.${docA._id}`)
  )
}

function transformChildBlockByMarkDef(block, markDef) {
  switch (markDef._type) {
    case "link":
      return {
        ...block,
        document: (
          <a href={markDef.url} className="underline">
            {block.document || block.text}
          </a>
        ),
      }
    case "internalLink":
      return {
        ...block,
        document: (
          <NavLink link={{ linkTo: markDef }} className="underline">
            {block.document || block.text}
          </NavLink>
        ),
      }
    case "strong":
      return {
        ...block,
        document: (
          <span className="font-bold">{block.document || block.text}</span>
        ),
      }
    case "em":
      return {
        ...block,
        document: (
          <span className="italic">{block.document || block.text}</span>
        ),
      }
    case "code":
      return {
        ...block,
        document: (
          <span className="tracking-widest font-mono">
            {block.document || block.text}
          </span>
        ),
      }
    case "underline":
      return {
        ...block,
        document: (
          <span className="underline">{block.document || block.text}</span>
        ),
      }
    case "strike-through":
      return {
        ...block,
        document: (
          <span className="line-through">{block.document || block.text}</span>
        ),
      }
    default:
      return {
        ...block,
        document: block.text,
      }
  }
}

function transformChildBlock(block, markDefs) {
  if (!block.marks.length) return block.text

  return block.marks.reduce((acc, cur) => {
    const markDef = markDefs[cur] || {
      _type: cur,
    }

    acc = transformChildBlockByMarkDef(acc, markDef)
    return acc
  }, block).document
}

export function blocksToComponents(blocks = []) {
  return (
    blocks
      // loop through each block
      .map((block) => {
        // if it's not a text block with children,
        // return nothing
        if (block._type !== "block" || !block.children) {
          return ""
        }

        const markDefs = block.markDefs.reduce((acc, cur) => {
          acc[cur._key] = cur
          return acc
        }, {})
        // loop through the children spans, and join the
        // text strings
        return block.children.map((child) =>
          transformChildBlock(child, markDefs)
        )
      })
  )
}
