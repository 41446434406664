import { useRouter } from "next/router"

export default function useNavigateToRoot() {
  const router = useRouter()

  const useNavigateToRoot = () => {
    router.push("/", undefined, {
      scroll: false,
    })
  }

  return useNavigateToRoot
}
