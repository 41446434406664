import classNames from "classnames"
import { useRef } from "react"
import { useEffect } from "react"
import Image from "../../components/Image"

export default function ModuleProcess({ module }) {
  const { image, items } = module
  const backgroundRef = useRef()

  const getItemStyle = (i) => {
    if (i === 1) return "bg-secondary md:left-1/4 lg:left-1/2 md:h-[50vh]"
    if (i === 2)
      return "bg-secondary-light md:left-1/2 lg:left-full md:h-[40vh] "
    return "bg-primary text-white h-[70vh] md:h-[45vh] absolute bottom-0"
  }

  const getStickyStyle = (i) => {
    if (i === 1) return "h-[60vh] top-[40vh]"
    if (i === 2) return "h-[50vh] top-[50vh]"
    return "h-screen"
  }

  const handleBackgroundScroll = () => {
    const { current } = backgroundRef
    if (current) {
      const rect = current.getBoundingClientRect()
      const { y, top } = rect
      if (y > 0) return
      const img = (current.querySelector(
        "span"
      ).style.transform = `translateY(${-1 * y}px)`)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleBackgroundScroll)
    return () => window.removeEventListener("scroll", handleBackgroundScroll)
  }, [])

  return (
    <div className="relative mt-32">
      <div className="sticky top-0 w-screen h-screen">
        <Image src={image.asset._id} layout="fill" />
      </div>
      <div className="md:site-grid h-full md:-mt-[100vh]">
        <div className="col-span-5 col-start-5 lg:col-span-4 lg:col-start-5 xl:col-span-3 xl:col-start-7 h-full">
          {items.map((item, i) => (
            <div
              className={classNames("sticky top-0 z-10", getStickyStyle(i))}
              key={item._key}
            >
              <div
                className={classNames(
                  "md:absolute md:bottom-0 w-full h-full p-4 md:p-8 text-lg",
                  getItemStyle(i)
                )}
              >
                <h2 className="text-3xl">{item.title}</h2>
                <p className="absolute top-4 md:top-8 right-4 md:right-8">
                  {i + 1}
                </p>
                <p className="pt-10 md:pt-0 md:absolute bottom-4 md:bottom-0 inset-x-4 md:inset-x-8 whitespace-pre-line md:max-h-[calc(100%-70px)] md:overflow-y-scroll md:pb-8">
                  {item.text}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
