import { useEffect, useRef, useState } from "react"
import { motion, AnimatePresence, isBrowser, useScroll } from "framer-motion"

// import Accordion from "components/Accordion"
import Image from "components/Image"
import Loader from "components/Loader"
import { AccordionItem } from "components/Accordion"
import PortableText from "react-portable-text"
import classNames from "classnames"

export default function ModuleProductAccordion({ module }) {
  const { items } = module
  if (items.length === 0) return null

  const [currentItem, setCurrentItem] = useState(0)
  const { image } = items[currentItem]
  const ref = useRef()
  const { scrollY } = useScroll()

  const scrollTo = (i) => {
    if (isBrowser)
      window.scrollTo({ top: ref.current.offsetTop + i * (window.innerHeight / (items.length-1)) })
  }

  useEffect(() => {
    return scrollY.onChange((latest) => {
      const scrollY = Math.max(0, latest - ref.current.offsetTop)
      const pct = scrollY / window.innerHeight
      const current = Math.min(
        items.length - 1,
        Math.floor(pct * items.length)
      )
      setCurrentItem(current)
    })
  }, [])

  return (
    <div
      className={classNames(
        "module-product-accordion mt-16 container lg:px-0",
        `h-[${100 * (items.length -1)}vh]`
      )}
      ref={ref}
    >
      <div className="sticky top-0 site-grid h-screen is-content">
        <div className="lg:order-last col-span-12 lg:col-span-8 relative h-[80vw] lg:h-screen">
          <AnimatePresence>
            <motion.div
              key={image.asset._id}
              className="absolute w-full pt-[100%] lg:pt-0 lg:h-full"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Image
                src={image.asset._id}
                className="absolute inset-0 object-cover z-10"
                objectFit="contain"
                sizes="(max-width: 1024px) 100vw, 80vw"
                aspect={1}
              />
              <Loader
                small
                className="absolute top-1/2 left-1/2 z-0 transform -translate-x-1/2 -translate-y-1/2"
              />
            </motion.div>
          </AnimatePresence>
        </div>
        <div className="col-span-12 lg:col-span-4 flex items-end sticky lg:static bottom-0 z-10">
          <div className="bg-white pb-10 lg:pl-8">
            {items.map(({ _key, title, text }, i) => (
              <AccordionItem
                key={_key}
                title={title}
                isOpen={i === currentItem}
                onToggle={() => scrollTo(i)}
              >
                <PortableText content={text} className="text-content" />
              </AccordionItem>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
