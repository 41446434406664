import modules from "../../config/modules"
import ModuleRenderer from "../ModuleRenderer"
import Layout from "../layouts/PageLayout"

export default function PageTemplate({
  page = {},
  preview,
  children,
  enabledLocales,
  globalSettings,
  fileGroups,
}) {
  const { modules: pageModules } = page
  const isRoot = globalSettings.homepage._ref === page._id

  return (
    <Layout
      enabledLocales={enabledLocales}
      globalSettings={globalSettings}
      fileGroups={fileGroups}
      isRoot={isRoot}
    >
      {pageModules ? (
        <ModuleRenderer
          page={page}
          modules={pageModules}
          preview={preview}
          components={modules}
          isRoot={isRoot}
          globalSettings={globalSettings}
        />
      ) : (
        children
      )}
    </Layout>
  )
}
