import { Swiper, SwiperSlide } from "swiper/react"
import { motion } from "framer-motion"
import { Mousewheel } from "swiper"

import { scrollSlideInAnimation } from "../../lib/animations"
import SwiperNavigation from "components/SwiperNavigation"

export default function ModuleTimeline({ module }) {
  const { label, items } = module

  return (
    <div className="overflow-x-hidden module-timeline">
      <motion.div {...scrollSlideInAnimation}>
        <Swiper
        modules={[Mousewheel]}
        mousewheel
        centeredSlides={false}
          spaceBetween={0}
          slidesPerView={1.5}
          breakpoints={{
            768: {
              slidesPerView: 2.5,
            },
            1024: {
              slidesPerView: 3.5,
            },
          }}
          className="mt-60"
        >
          <SwiperSlide className="timeline-first-slide">
            <p>{label}</p>
          </SwiperSlide>
          {items.map(({ title, text }, i) => (
            <SwiperSlide className="md:pr-16 lg:pr-32 p-8" key={i}>
              <div className="bg-alternative w-[13px] h-[13px] absolute top-0 left-8 -translate-y-1/2 rounded-full" />
              <p className="text-6xl md:text-8xl mb-5">{title}</p>
              <p className="whitespace-pre-line">{text}</p>
            </SwiperSlide>
          ))}
          <div
            slot="container-start"
            className="border-b border-b-grayed-muted"
          >
            <div className="container pb-5 flex justify-end gap-2 ">
              <SwiperNavigation />
            </div>
          </div>
        </Swiper>
      </motion.div>
    </div>
  )
}
