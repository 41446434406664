import templates from "../config/templates"

function usePage({ data, preview, isPage }) {
  if (!data) return {}

  const TemplateComponent = isPage
    ? templates["page"]
    : templates[data.page._type] || templates["page"]

  return {
    TemplateComponent,
    page: data.page,
    type: data.page._type,
    preview,
  }
}

export default usePage
