import Link from "next/link"
import PortableText from "react-portable-text"
import { path } from "../../lib/helpers"

export default function ModuleText({ module }) {
  const { title, body, alignment } = module
  return (
    <div className="container site-grid my-32">
      <div className="col-span-8 col-start-5">
        {title && <h2 className="text-4xl mb-5">{title}</h2>}
        {body && (
          <PortableText
            className="text-content"
            content={body}
            serializers={{
              normal: (props) => (
                <p className="text-lg mb-2 leading-[1.5]" {...props} />
              ),
              // Annotations
              link: ({ children, url }) => (
                <a href={url} className="underline" target="_blank">
                  {children}
                </a>
              ),
            }}
          />
        )}
      </div>
    </div>
  )
}
