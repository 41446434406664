import classNames from "classnames"
import NavLink from "components/NavLink"
import { motion } from "framer-motion"
import { scrollRevealAnimation } from "../../lib/animations"

export default function ModuleCTA({ module }) {
  const { title, subtitle, link, style } = module
  const primary = style === "primary"

  return (
    <div
      className={classNames(
        "module-cta pb-32 md:pb-40",
        primary
          ? "bg-primary text-white pt-32 md:pt-40 lg:pt-60"
          : "bg-secondary-light pt-10"
      )}
    >
      <div className="container site-grid">
        <div
          className={classNames(
            "justify-center items-center",
            primary
              ? "col-span-10 col-start-2 lg:col-span-6 lg:col-start-4"
              : "col-span-9 col-start-5 lg:col-span-5 lg:col-start-7"
          )}
        >
          <motion.div
            className={classNames({
              "text-center": primary,
            })}
            {...scrollRevealAnimation}
          >
            <h2
              className={
                primary
                  ? "text-4xl md:text-5xl lg:text-6xl text-white"
                  : "text-4xl md:text-5xl"
              }
            >
              {title}
            </h2>
            <h3
              className={
                primary
                  ? "text-4xl md:text-5xl lg:text-6xl text-white-muted"
                  : "text-4xl md:text-5xl"
              }
            >
              {subtitle}
            </h3>
            <NavLink
              link={link}
              className={classNames("btn mt-10", { "btn-inverted": primary })}
            />
          </motion.div>
        </div>
      </div>
    </div>
  )
}
