import { isBrowser } from "framer-motion"
import { useEffect, useState } from "react"
import { blocksToComponents } from "../lib/helpers"
import { useTranslations } from "lib/useTranslations.client"

export default function NotificationBar({
  notification: { enabled, notification, id },
}) {
  const { t } = useTranslations()
  const [visible, setVisible] = useState(false)
  const localStorageId = id

  useEffect(() => {
    if (!isBrowser) return false
    if (localStorage.getItem(localStorageId) !== "false" && enabled)
      setVisible(true)
  }, [])

  useEffect(() => {
    document.body.classList.toggle("notificationbar-visible", visible === true)
    localStorage.setItem(localStorageId, visible)
  }, [visible])

  if (!visible) return null
  return (
    <div
      className={
        "notificationbar fixed top-0 flex items-center left-0 w-full bg-primary text-white text-xl z-header overflow-hidden"
      }
    >
      <div className="container relative flex justify-between">
        <span>{blocksToComponents(notification)}</span>
        <button onClick={() => setVisible(false)} className="underline">
          {t("general.close")}
        </button>
      </div>
    </div>
  )
}
