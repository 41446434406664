import { getContentBySlugQuery, draftQuery, getQueryMap } from "./requestQuery"
import { getClient } from "lib/sanity.server"
import { verify } from "./jwt.server"
import UrlPattern from "url-pattern"
import { groq } from "next-sanity"

const getFilteredSlug = ({ slug }) => {
  if (!slug || slug.includes("__webpack_hmr")) return "/"
  // Remove page number if slug is calcucator, in order to query Sanity correctly
  if (slug[0] === "calculator") return slug[0]
  // Check if page is requested
  return slug.join("/")
}

export default async function resolvePageProps({
  preview,
  params,
  query,
  locale,
}) {
  const skipProtection = query.at ? verify(query.at) : false
  const queryParams = {
    preview,
    skipProtection, // determine this value by checking the JWT in the URL
    slug: getFilteredSlug(params),
  }

  const { match, query: optimizedQuery } = getQuery(queryParams.slug, locale)

  if (match == "/*" && params.slug) {
    const redirect = await fetchRedirectRoute(params.slug.join("/"))
    if (redirect) return { redirect: "/" + redirect.to.slug.current }
  }

  const data =
    (await getClient(preview).fetch(
      getContentBySlugQuery(optimizedQuery, locale),
      queryParams
    )) || {}
  const { globalSettings, page, fileGroups } = data

  return {
    page,
    globalSettings,
    fileGroups,
    queryParams,
    query: optimizedQuery,
    draftQuery,
  }
}

const getQuery = (path, locale) => {
  const queryMap = getQueryMap(locale)
  const matchers = Object.keys(queryMap).reduce((matchers, route) => {
    matchers[route] = new UrlPattern(route)
    return matchers
  }, {})

  for (let route in matchers) {
    if (matchers[route].match("/" + path))
      return { match: route, query: queryMap[route] }
  }
}

const fetchRedirectRoute = async (slug) => {
  const { redirect } = await getClient().fetch(
    groq`{
            "redirect": coalesce(
                *[_type == 'redirect' && from == $slug][0]{ to-> { slug } },
            )
        }`,
    { slug: "/" + slug }
  )
  return redirect
}
