import Link from "next/link"
import { path, modalPageTypes } from "../lib/helpers"
import useShallowRoute from "../lib/useShallowRoute"
import { useRouter } from "next/router"

const NavLink = ({
  link: { label, linkTo, external, url, hash } = {},
  className = "",
  children,
}) => {
  if (external)
    return (
      <a href={url} className={className}>
        {label}
      </a>
    )

  const { changeShallowRoute } = useShallowRoute()
  const router = useRouter()

  const handleClick = (e) => {
    const { href } = e.currentTarget
    if (
      modalPageTypes
        .map(({ type }) => type)
        .includes(href.replace("//", "").split("/")[router.locale === "en" ? 1 : 2])
    )
      changeShallowRoute(e)

    if (hash) {
      setTimeout(() => {
        window.scrollTo({
          top: window.scrollY - (window.innerWidth < 768 ? 50 : 0),
        })
      }, 20)
    }
  }

  return (
    linkTo && (
      <Link href={path(linkTo.slug.current, hash)} passHref>
        <a className={className} onClick={handleClick}>
          {label || linkTo.title || children}
        </a>
      </Link>
    )
  )
}

export default NavLink
