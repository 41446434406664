import Accordion from "components/Accordion"

export default function ModuleTextAccordion({ module }) {
  const { items } = module
  if (items?.length === 0) return null
  return (
    <div className="site-grid container mt-32 module-text-accordion">
      <Accordion
        items={items}
        className="col-span-8 col-start-5 lg:col-span-6 lg:col-start-7"
      />
    </div>
  )
}
