import kebabCase from "lodash.kebabcase"
import PortableText from "react-portable-text"
import classNames from "classnames"
import { isBrowser, motion } from "framer-motion"

import { scrollRevealAnimation } from "../../lib/animations"

export default function ModuleText({ module, isRoot }) {
  const { title, body, style, label } = module
  const primary = style === "primary"

  return (
    <motion.div
      id={kebabCase(label)}
      className={classNames(
        "module-text",
        primary ? "is-primary" : " container"
      )}
      {...scrollRevealAnimation}>
      <div
        className={classNames(
          "site-grid border-t border-t-grayed-light pt-4 lg:pt-9",
          primary ? "container" : ""
        )}>
        {primary && (
          <div className="col-span-4 lg:col-span-6">{!isRoot ? label : ""}</div>
        )}
        <div className="col-span-8 lg:col-span-6">
          {title && (
            <h2
              className={
                primary
                  ? "text-4xl md:text-5xl lg:text-6xl"
                  : "text-3xl md:text-4xl lg:text-5xl"
              }>
              {title}
            </h2>
          )}
          {body && (
            <PortableText
              className="mt-14 text-content"
              content={body}
              serializers={{
                normal: (props) => (
                  <p className="text-2xl mb-2 leading-[1.5]" {...props} />
                ),
                // Annotations
                link: ({ children, url }) => (
                  <a href={url} className="underline" target="_blank">
                    {children}
                  </a>
                ),
              }}
            />
          )}
        </div>
      </div>
    </motion.div>
  )
}
