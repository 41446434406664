import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useState,
} from "react"
import axios from "axios"
import Link from "next/link"
import { useRouter } from "next/router"
import classNames from "classnames"
import PortableText from "react-portable-text"
import { motion, AnimatePresence, isBrowser } from "framer-motion"

import calculations from "lib/calculator/calculations"
import useShallowRoute from "lib/useShallowRoute"

import Counter from "components/Counter"
import FormField from "components/FormField"
import TitleBar from "components/TitleBar"
import Sidebar from "components/Sidebar"
import Image from "components/Image"
import { useTranslations } from "lib/useTranslations.client"

const steps = [
  { label: "1", path: "", navigatable: true },
  { label: "2", path: "2", navigatable: true },
  { label: "3", path: "3", navigatable: true },
  { label: "4", path: "4", navigatable: true },
  { label: "thank you", path: "thank-you", navigatable: false },
]

const initialState = {
  units: "m",
  product: {
    type: null,
    measurement: null,
    activePrice: 0,
    passivePrice: 0,
  },
  details: {
    active: null,
    passive: null,
    location: {
      continent: "",
      country: "",
    },
  },
  options: {
    color: null,
    material: null,
  },
  contact: {
    project: null,
    name: null,
    email: null,
  },
}

const InputContext = createContext(null)
const InputDispatchContext = createContext(null)

function reducer(state, action) {
  switch (action.type) {
    case "SET_PRODUCT":
      return { ...state, product: action.product }
    case "SET_UNITS":
      return { ...state, units: action.units }
    case "SET_ACTIVE":
      return { ...state, details: { ...state.details, active: action.value } }
    case "SET_PASSIVE":
      return { ...state, details: { ...state.details, passive: action.value } }
    case "SET_CONTINENT":
      return {
        ...state,
        details: {
          ...state.details,
          location: { ...state.details.location, continent: action.value },
        },
      }
    case "SET_COUNTRY":
      return {
        ...state,
        details: {
          ...state.details,
          location: { ...state.details.location, country: action.value },
        },
      }
    case "SET_MATERIAL":
      return {
        ...state,
        options: {
          ...state.options,
          material: action.value,
        },
      }
    case "SET_COLOR":
      return {
        ...state,
        options: {
          ...state.options,
          color: action.value,
        },
      }
    case "SET_CONTACT":
      return {
        ...state,
        contact: {
          ...state.contact,
          [action.name]: action.value,
        },
      }
    default:
      throw new Error()
  }
}

const getMaxSteps = () => {
  const { product, details, options, contact } = useContext(InputContext)
  if (
    contact.name &&
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contact.email) &&
    contact.consent
  )
    return 4
  if (options.color && options.material) return 3
  if (details.active && details.location.continent && details.location.country)
    return 2
  if (product.type) return 1
  return 0
}

const getCurrentStep = () => {
  const { query } = useRouter()
  const step = (query.slug && query.slug[1]) || ""
  return steps.find((s) => s.path === step)
}

const getNextStep = () => {
  let found = false
  return steps.find((s, i) => {
    if (found || i === steps.length - 1) return true
    if (s === getCurrentStep()) found = true
  })
}

const getIsNarrow = () => {
  return isBrowser && window.innerWidth < 1024
}

const isLastStep = () => {
  return getCurrentStep() === steps[steps.length - 1]
}

const getCurrentStepIndex = () => {
  return steps.findIndex((s) => s === getCurrentStep())
}

const canGoToStep = (step) => {
  return steps.findIndex((s) => s === step) <= getMaxSteps()
}

const getCountriesList = (location) => {
  const { details } = useContext(InputContext)
  if (!details.location.continent) return []
  return location.items.find((c) => c._key === details.location.continent)
    .countries
}

export default function CalculatorTemplate({ page = {} }) {
  if (!isBrowser) return null

  const { products, details, options, submit } = page
  const [initiated, setInitiated] = useState(false)
  const state = {
    ...initialState,
    ...JSON.parse(localStorage.getItem("calculator")),
  }
  const [inputs, dispatch] = useReducer(reducer, state)
  const step = getCurrentStep().path

  const isNarrow = getIsNarrow()

  useEffect(() => {
    if (initiated) localStorage.setItem("calculator", JSON.stringify(inputs))
    setInitiated(true)
  }, [inputs])

  useEffect(() => {
    const sp = document.querySelector(".scrollable-panel")
    if (sp) {
      sp.scrollTo({ top: 0, behavior: "smooth" })
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }, [step])

  return (
    <div className="flex flex-col h-wh w-screen relative">
      <InputContext.Provider value={inputs}>
        <InputDispatchContext.Provider value={dispatch}>
          <TitleBar progress={getCurrentStepIndex() / (steps.length - 1)}>
            <TitlebarSteps />
          </TitleBar>
          <AnimatePresence>
            <motion.div
              className="h-full bg-white"
              key={step}
              transition={{
                ease: "easeInOut",
                duration: 0.8,
              }}
              initial={isNarrow ? { x: "100%" } : { marginTop: "100%" }}
              animate={{
                ...(isNarrow
                  ? {
                      x: 0,
                      transition: {
                        ease: "easeInOut",
                        duration: 0.8,
                      },
                    }
                  : { marginTop: 0 }),
                transitionEnd: {
                  position: "static",
                },
              }}
              exit={
                isNarrow
                  ? {
                      x: "-100%",
                      position: "absolute",
                      top: 50,
                      transition: {
                        duration: 0.8,
                        ease: "easeInOut",
                      },
                    }
                  : { marginTop: "-100%", position: "absolute" }
              }
            >
              {step === "" && <Products content={products} />}
              {step === "2" && <Details content={details} />}
              {step === "3" && <Options content={options} page={page} />}
              {step === "4" && <Submit content={submit} />}
              {step === "thank-you" && <Receipt content={submit} />}
            </motion.div>
          </AnimatePresence>
        </InputDispatchContext.Provider>
      </InputContext.Provider>
    </div>
  )
}

const TitlebarSteps = () => {
  const completed = isLastStep()
  return (
    <div
      className={classNames({
        "text-grayed-muted pointer-events-none": completed,
      })}
    >
      Step{" "}
      {steps
        .filter((s) => s.navigatable)
        .map((s) => (
          <Link key={s.path} href={`/calculator/${s.path}`} passHref>
            <a
              className={classNames(
                "px-1 cursor-pointer disabled:cursor-default",
                { disabled: !canGoToStep(s) },
                {
                  "text-grayed-muted": s !== getCurrentStep(),
                }
              )}
            >
              {s.label}
            </a>
          </Link>
        ))}
    </div>
  )
}

const Title = ({ children }) => {
  const animation = !getIsNarrow() && {
    transtion: { duration: 0.8, ease: "easeInOut" },
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: { delay: 0.3, duration: 0.8, ease: "easeInOut" },
    },
    exit: { opacity: 0 },
  }
  return (
    <motion.div
      {...animation}
      className="lg:fixed top-[50px] bottom-0 flex flex-col justify-between p-4 lg:p-8"
    >
      {children}
    </motion.div>
  )
}

const NextStepButton = ({ label, className = "", onClick }) => {
  const { t } = useTranslations()

  const { changeShallowRoute } = useShallowRoute({ onClick })

  return (
    <Link href={`/calculator/${getNextStep().path}`} passHref>
      <a
        className={classNames(
          "btn btn-primary pointer-events-auto text-2xl",
          { disabled: !canGoToStep(getNextStep()) },
          className
        )}
        onClick={changeShallowRoute}
      >
        {label ? label : t("calculator.next-step")}
      </a>
    </Link>
  )
}

const MeasurementInput = ({
  value,
  onChange,
  labels: { units, unitsIsMeters, squared, isSquared },
}) => {
  const { t } = useTranslations()

  const key = `enter-${isSquared ? "area" : "length"}-in-${
    unitsIsMeters ? "meters" : "feet"
  }`
  const placeholder = t(`calculator.${key}`)

  return (
    <div className="relative lg:w-1/2">
      {!!value && (
        <div className="border border-transparent absolute inset-x-4 inset-y-1 pointer-events-none">
          <span className="opacity-0 mr-1">{value}</span>
          {units}
          {squared}
        </div>
      )}
      <input
        type="number"
        className="border border-grayed-muted px-4 py-1 rounded-full w-full"
        onChange={onChange}
        defaultValue={value}
        placeholder={placeholder}
      />
    </div>
  )
}

const StickyFooter = ({ children, className = "" }) => {
  return (
    <div
      className={classNames(
        "sticky bottom-0 right-4 lg:right-8 md:mt-12 py-4 lg:py-5 bg-white w-auto",
        className
      )}
    >
      <div className="relative z-20 text-right">{children}</div>
    </div>
  )
}

const Info = ({ onClick }) => {
  return (
    <button onClick={onClick} className="ml-2">
      <img src="/assets/icons/info.svg" alt="Info" />
    </button>
  )
}

const Checkmark = ({ checked, tabIndex = false }) => {
  return (
    <span
      {...(tabIndex ? { tabIndex } : {})}
      className={classNames(
        "border border-black rounded-full w-[18px] h-[18px] mr-2 flex items-center justify-center shrink-0",
        { "bg-black": checked }
      )}
    >
      {checked && (
        <svg width="9px" height="7px" viewBox="0 0 9 7">
          <polygon
            id="Path"
            fill="#FFFFFF"
            points="3.125 6.6192 0 3.49425 0.99413 2.5 3.125 4.6308 7.7563 -5.68434189e-14 8.7536 0.99119"
          />
        </svg>
      )}
    </span>
  )
}

const CheckTitle = ({ title, isActive, onClick }) => (
  <div
    className="flex items-center text-lg md:text-2xl mb-6 cursor-pointer"
    onClick={onClick}
  >
    <Checkmark checked={isActive} />
    {title}
  </div>
)

const Products = ({ content }) => {
  const { title, lead, text, products } = content
  const { product: productInput } = useContext(InputContext)
  const dispatch = useContext(InputDispatchContext)

  const setProduct = (product) =>
    dispatch({
      type: "SET_PRODUCT",
      product: {
        type: product._key,
        ...product,
      },
    })

  return (
    <div className="flex flex-col h-full">
      <div className="lg:site-grid h-full relative">
        <div className="col-span-8 pb-4">
          <Title>
            <h1 className="text-5xl lg:text-7xl">{title}</h1>
          </Title>
        </div>
        <div className="col-span-4 px-4 lg:pt-8 lg:pr-8">
          <p className="text-base whitespace-pre-line">{lead}</p>
          <p className="text-2xl mt-8 whitespace-pre-line">{text}</p>
          <NextStepButton className="hidden lg:inline-block mt-8" />
        </div>
      </div>
      <div className="col-span-full lg:site-grid mt-4 p-4 pb-0 lg:p-8">
        {products.map((product) => {
          const { _key, title, image } = product
          const isActive = _key === productInput.type

          return (
            <div
              key={_key}
              className="col-span-4 cursor-pointer mt-4"
              onClick={() => setProduct(product)}
            >
              <div
                className={classNames(
                  "relative border transition-all duration-200 hover:border-secondary hover:bg-transparent",
                  isActive ? "border-secondary" : "bg-secondary-light"
                )}
              >
                <div className="relative pt-[74%] overflow-hidden">
                  <div className="absolute inset-6 bottom-0">
                    <CheckTitle isActive={isActive} title={title} />
                    <div className="image-container">
                      <Image
                        src={image.asset._id}
                        alt={title}
                        objectFit="contain"
                        objectPosition="bottom"
                        className="image"
                        sizes="(max-width: 640px) 100vw, 33vw"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <StickyFooter className="lg:hidden pr-4">
        <NextStepButton />
      </StickyFooter>
    </div>
  )
}

const Details = ({ content }) => {
  const { t } = useTranslations()

  const { title, squared, location, length } = content
  const {
    units,
    product: { measurement },
    details,
  } = useContext(InputContext)

  const dispatch = useContext(InputDispatchContext)
  const unitsIsMeters = units === "m"
  const isSquared = measurement === "squaremeters"

  const inputLabels = {
    units,
    unitsIsMeters,
    isSquared,
    squared: isSquared ? "²" : "",
    measurement: isSquared ? "area" : "length",
  }

  const { text, active, passive } = isSquared ? squared : length

  const setUnits = (v) =>
    dispatch({
      type: "SET_UNITS",
      units: v.currentTarget.checked ? "m" : "ft",
    })

  const setActive = (value) => dispatch({ type: "SET_ACTIVE", value })
  const setPassive = (value) => dispatch({ type: "SET_PASSIVE", value })
  const setContinent = (value) => dispatch({ type: "SET_CONTINENT", value })
  const setCountry = (value) => dispatch({ type: "SET_COUNTRY", value })

  return (
    <div className="flex flex-col">
      <div className="lg:site-grid relative">
        <div className="col-span-6">
          <Title>
            <h1 className="text-5xl lg:text-7xl">{title}</h1>
          </Title>
        </div>
        <div className="col-span-6 text-xl p-4 pb-0 lg:p-8">
          <p className="mb-4 text-xl leading-7">{text}</p>
          <div className="toggle-switch border border-grayed-muted w-28 py-1 px-4 rounded-full inline-block relative mb-14">
            <input
              type="checkbox"
              name="toggleSwitch"
              className="hidden"
              id="toggleSwitch"
              onChange={setUnits}
              checked={unitsIsMeters}
            />
            <label
              className="flex justify-between cursor-pointer select-none"
              htmlFor="toggleSwitch"
            >
              <span
                className={classNames(
                  "absolute top-0 left-0 w-14 h-full bg-black rounded-full text-white px-4 py-1 transform duration-200",
                  { "translate-x-full": !unitsIsMeters }
                )}
              >
                {units}
                {inputLabels.squared}
              </span>
              <span>m{inputLabels.squared}</span>{" "}
              <span>ft{inputLabels.squared}</span>
            </label>
          </div>
          <div className="border-t border-grayed-muted pt-4 mb-14">
            <p>{active.title}</p>
            <p className="text-grayed mb-8">{active.text}</p>
            <MeasurementInput
              value={details.active}
              onChange={(e) => setActive(e.currentTarget.value)}
              labels={inputLabels}
            />
          </div>
          <div className="border-t border-grayed-muted pt-4 mb-14">
            <p>{passive.title}</p>
            <p className="text-grayed mb-8">{passive.text}</p>
            <MeasurementInput
              value={details.passive}
              onChange={(e) => setPassive(e.currentTarget.value)}
              labels={inputLabels}
            />
          </div>
          <div className="border-t border-grayed-muted pt-4 mb-14">
            <p>{location.title}</p>
            <p className="text-grayed mb-8">{location.text}</p>
            <div className="mb-4">
              <select
                defaultValue={details.location.continent}
                onChange={(e) => setContinent(e.currentTarget.value)}
                className="border border-grayed-muted px-4 py-1 rounded-full w-full lg:w-1/2 disabled:opacity-30 appearance-none"
              >
                <option value="" disabled>
                  {t("calculator.select-continent")}
                </option>
                {location.items.map(({ _key, title }) => (
                  <option key={_key} value={_key}>
                    {title}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <select
                defaultValue={details.location.country}
                onChange={(e) => setCountry(e.currentTarget.value)}
                className="border border-grayed-muted px-4 py-1 rounded-full w-full lg:w-1/2 disabled:opacity-30 appearance-none"
                disabled={!details.location.continent}
              >
                <option value="" disabled>
                  {t("calculator.select-country")}
                </option>
                {getCountriesList(location).map(({ _key, title }) => (
                  <option key={_key} value={_key}>
                    {title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <StickyFooter>
            <NextStepButton />
          </StickyFooter>
        </div>
      </div>
    </div>
  )
}

const Options = ({ content, page }) => {
  const { t } = useTranslations()

  const { title, text, materials, colors } = content
  const {
    estimateFootnote,
    details: { location },
    installPrice,
  } = page
  const dispatch = useContext(InputDispatchContext)
  const {
    units,
    options,
    product,
    product: { measurement, installText },
    details: {
      active,
      passive,
      location: { country: countryKey },
    },
  } = useContext(InputContext)
  const country = getCountriesList(location).find((c) => c._key === countryKey)

  const isSquared = measurement === "squaremeters"

  const setMaterial = (value) => dispatch({ type: "SET_MATERIAL", value })
  const setColor = (value) => dispatch({ type: "SET_COLOR", value })

  const [isOpen, setIsOpen] = useState(false)
  const [selectedSidebar, setSelectedSidebar] = useState({
    title: "",
    text: "",
    items: [],
  })
  const {
    getTotalPrice,
    getInstallationCost,
    getInstalledEffect,
    getEffectByDirection,
  } = calculations({
    ...page,
    units,
    active,
    passive,
    product,
    measure: measurement,
    country,
    material: materials.items.find((m) => m._key === options.material),
    color: colors.items.find((m) => m._key === options.color),
    installPrice,
  })

  useEffect(() => {
    if (isOpen) setSelectedSidebar(isOpen === "materials" ? materials : colors)
  }, [isOpen])

  return (
    <>
      <div className="flex flex-col">
        <div className="lg:site-grid relative">
          <div className="col-span-6">
            <Title>
              <h1 className="text-5xl lg:text-7xl">{title}</h1>
            </Title>
          </div>
          <div className="col-span-6 text-xl p-4 pb-0 lg:p-8 lg:pb-0">
            <p className="mb-8 text-xl leading-7">{text}</p>
            <div className="border-t border-grayed-muted pt-4 mb-14">
              <p className="mb-5 text-2xl flex">
                {materials?.title}{" "}
                <Info onClick={() => setIsOpen("materials")} />
              </p>
              <div className="grid gap-4 lg:gap-8 grid-cols-6">
                {materials?.items.map(({ _key, title, image }) => {
                  const isActive = options.material === _key
                  return (
                    <div
                      key={_key}
                      className="col-span-3 md:col-span-2 options__item"
                      onClick={() => setMaterial(_key)}
                    >
                      <div
                        className={classNames(
                          "cursor-pointer border mb-4 transition-all duration-100 hover:p-2 hover:border-black options__img",
                          {
                            "p-2 border-black": isActive,
                          }
                        )}
                      >
                        <div className="relative pt-[100%]">
                          <Image
                            src={image.asset._id}
                            alt={title}
                            className="image"
                            sizes="(max-width: 640px) 100vw, 15vw"
                          />
                        </div>
                      </div>
                      <CheckTitle isActive={isActive} title={title} />
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="border-t border-grayed-muted pt-4 mb-14">
              <p className="mb-5 text-2xl flex">
                {colors?.title} <Info onClick={() => setIsOpen("colors")} />
              </p>
              <div className="grid gap-4 lg:gap-8 grid-cols-6">
                {colors?.items.map(({ _key, title, image }) => {
                  const isActive = options.color === _key
                  return (
                    <div
                      key={_key}
                      className="col-span-3 md:col-span-2 options__item"
                      onClick={() => setColor(_key)}
                    >
                      <div
                        className={classNames(
                          "cursor-pointer border mb-4 transition-all duration-100 hover:p-2 hover:border-black options__img",
                          {
                            "p-2 border-black": isActive,
                          }
                        )}
                      >
                        <div className="relative pt-[100%]">
                          <Image
                            src={image.asset._id}
                            alt={title}
                            className="image"
                            sizes="(max-width: 640px) 100vw, 15vw"
                          />
                        </div>
                      </div>
                      <CheckTitle isActive={isActive} title={title} />
                    </div>
                  )
                })}
              </div>
            </div>
            <motion.div
              transition={{ duration: 0.3, ease: "easeInOut" }}
              initial={{ x: "-100%" }}
              animate={{ x: 0 }}
              exit={{ x: "-100%" }}
              className="lg:fixed bottom-0 left-0 lg:p-8 flex flex-col lg:w-1/2 xl:w-1/3 gap-6 text-base md:text-lg"
            >
              <div>
                <p>{t("calculator.cost-estimate")}</p>
                <p className="text-grayed">
                  ≈ <Counter to={getTotalPrice(false)} /> €
                </p>
              </div>
              <div>
                <p>{t("calculator.installation-cost-estimate")}</p>
                <p className="text-grayed">
                  ≈ <Counter to={getInstallationCost(false)} /> €
                </p>
              </div>
              <div>
                <p>{t("calculator.installed-capacity-estimate")}</p>
                <p className="text-grayed">
                  ≈ <Counter from={0} to={getInstalledEffect(false)} /> kWp
                </p>
              </div>
              {isSquared && (
                <>
                  <div>
                    <p>{t("calculator.output-north-estimate")}</p>
                    <p className="text-grayed">
                      ≈ <Counter to={getEffectByDirection("north", false)} />{" "}
                      kWh
                    </p>
                  </div>
                  <div className="mb-5 lg:mb-12">
                    <p>{t("calculator.output-south-estimate")}</p>
                    <p className="text-grayed">
                      ≈ <Counter to={getEffectByDirection("south", false)} />{" "}
                      kWh
                    </p>
                  </div>
                </>
              )}
              <div>
                {country.distributor?.locationText && (
                  <>
                    <p className="whitespace-pre-wrap text-grayed col-span-12">
                      {country.distributor.locationText}
                    </p>
                    <br />
                  </>
                )}
                {installText && (
                  <>
                    <p className="whitespace-pre-wrap text-grayed col-span-12">
                      {installText}
                    </p>
                    <br />
                  </>
                )}
                <p className="whitespace-pre-wrap text-grayed col-span-12">
                  {estimateFootnote}
                </p>
              </div>
            </motion.div>
            <StickyFooter>
              <NextStepButton label={t("calculator.get-pdf")} />
            </StickyFooter>
          </div>
        </div>
        <div className="col-span-full lg:site-grid"></div>
      </div>
      <Sidebar
        title={selectedSidebar?.title}
        isOpen={isOpen}
        onClose={() => setIsOpen((o) => false)}
      >
        <div className="p-4 lg:p-8">
          <p className="mb-14 whitespace-pre-line">{selectedSidebar?.text}</p>
          {selectedSidebar?.items.map((material) => {
            const { title, image, body } = material
            return (
              <div className="mb-14 last:mb-0">
                <div className="image-container mb-4">
                  <Image
                    src={image.asset._id}
                    alt={title}
                    className="image"
                    sizes="(max-width: 640px) 100vw, 20vw"
                    aspect={0.5}
                  />
                </div>
                <PortableText content={body} className="text-content" />
              </div>
            )
          })}
        </div>
      </Sidebar>
    </>
  )
}

const Submit = ({ content }) => {
  const { t } = useTranslations()

  const { title, text } = content
  const inputs = useContext(InputContext)
  const dispatch = useContext(InputDispatchContext)
  const { contact } = inputs
  const router = useRouter()

  const handleChange = (e) => {
    const { name, value } = e.currentTarget
    dispatch({ type: "SET_CONTACT", name, value })
  }

  const onSubmit = () => {
    axios
      .post("/api/estimation-email", inputs, {
        headers: {
          "Accept-Language": router.locale,
        },
      })
      .then((res) => {
        localStorage.removeItem("calculator")
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <div className="flex flex-col h-full">
      <div className="lg:site-grid relative h-full">
        <div className="col-span-6">
          <Title>
            <h1 className="text-5xl lg:text-7xl">{title}</h1>
          </Title>
        </div>
        <div className="col-span-6 p-4 pb-0 lg:p-8 lg:pb-20 flex items-center">
          <div className="border-t border-t-grayed-muted pt-5">
            <p className="mb-8 text-xl leading-7 whitespace-pre-line">{text}</p>
            <FormField
              name="project"
              value={contact.project}
              placeholder={t("forms.project-name")}
              onChange={handleChange}
              className="lg:w-3/4"
            />
            <FormField
              name="name"
              value={contact.name}
              placeholder={t("forms.name")}
              required
              onChange={handleChange}
              className="lg:w-3/4"
            />
            <FormField
              name="email"
              value={contact.email}
              placeholder={t("forms.email")}
              type="email"
              required
              onChange={handleChange}
              className="lg:w-3/4"
            />
            <div className="relative mt-10 mb-14">
              <label
                className="flex text-grayed relative z-10 cursor-pointer"
                htmlFor="consent"
              >
                <Checkmark checked={contact.consent} />
                {t("forms.consent")}
              </label>
              <input
                id="consent"
                name="consent"
                type="checkbox"
                className="hidden"
                onChange={(e) =>
                  dispatch({
                    type: "SET_CONTACT",
                    name: "consent",
                    value: e.currentTarget.checked,
                  })
                }
              />
            </div>
            <StickyFooter>
              <NextStepButton
                label={t("calculator.send-pdf")}
                onClick={onSubmit}
              />
            </StickyFooter>
          </div>
        </div>
      </div>
      <div className="col-span-full lg:site-grid"></div>
    </div>
  )
}

const Receipt = ({ content }) => {
  const { t } = useTranslations()

  const { contact } = useContext(InputContext)
  const { receipt } = content
  const inputs = useContext(InputContext)
  const router = useRouter()

  let [isDownloading, setIsDownloading] = useState(null)

  const downloadPDF = () => {
    setIsDownloading(true)
    axios
      .post("/api/estimation-pdf", inputs, {
        responseType: "arraybuffer",
        headers: {
          "Accept-Language": router.locale,
        },
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `${inputs.contact.project}.pdf`) //or any other extension
        document.body.appendChild(link)
        link.click()
        setIsDownloading(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const replaceVars = (content) => {
    return content.map((item) => {
      return {
        ...item,
        children: item.children.map((child) => {
          return {
            ...child,
            text: child.text.replace(
              "{{name}}",
              contact.name?.split(" ")[0] || ""
            ),
          }
        }),
      }
    })
  }

  return (
    <div className="grid grid-cols-12 gap-4 lg:gap-8 h-full">
      <div className="col-span-10 col-start-2 lg:col-span-8 lg:col-start-3 text-center flex items-center">
        <div>
          <PortableText
            content={replaceVars(receipt)}
            className="text-2xl md:text-5xl lg:text-7xl text-grayed mb-12 text-content"
          />
          <button
            onClick={downloadPDF}
            className="btn btn-primary text-2xl mr-4"
            disabled={isDownloading}
          >
            {isDownloading
              ? t("calculator.downloading")
              : t("calculator.download")}
          </button>
          <Link href="/">
            <a className="btn text-2xl mr-4 mb-2">{t("general.close")}</a>
          </Link>
        </div>
      </div>
    </div>
  )
}
