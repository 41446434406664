export default function calculations({
  units,
  measure,
  active,
  passive,
  country,
  material,
  color,
  product,
  discounts,
  installPrice,
  priceRoundBy = 1,
  effectRoundBy = 1,
}) {
  const isSquared = measure === "squaremeters"
  const mToFt = units === "m" ? 1 : isSquared ? 10.7639 : 3.28084
  const activeSize = Number(active)
  const passiveSize = Number(passive)
  const totalSize = (activeSize + passiveSize) / mToFt
  const effectFactor =
    Number(material?.effectFactor || 1) * Number(color?.effectFactor || 1)
  const installedEffect =
    ((product.peakOutput * activeSize +
      (isSquared ? 0 : passiveSize * product.peakOutput * 2)) *
      effectFactor) /
    1000

  // ----------------------------------------
  // Helper functions for PDF data

  const roundBy = (num, roundBy = 1) => Math.round(num / roundBy) * roundBy

  const formatNumber = (num, round = 1) =>
    Number(roundBy(num, round)).toLocaleString("da-DK")

  const getDiscountFactor = () => {
    if (!isSquared) return 1 // No discounts for Louvre
    const d = discounts.sort((a, b) => (a.size < b.size ? -1 : 1))
    return (d.reverse().find((d) => totalSize >= d.size) || { factor: 1 })
      .factor
  }

  const getTotalPrice = (format = true) => {
    const materialPF = Number(material?.priceFactor || 1)
    const colorPF = Number(color?.priceFactor || 1)
    const distributorCostFactor = Number(country?.distributor?.costFactor) || 1
    const result =
      ((active * product.activePrice +
        (passive / mToFt) * product.passivePrice) /
        mToFt) *
      materialPF *
      colorPF *
      getDiscountFactor() *
      distributorCostFactor
    if (!format) return roundBy(result, priceRoundBy)
    return `≈ ${formatNumber(result, priceRoundBy)} €`
  }

  const getInstalledEffect = (format = true) => {
    if (!format) return roundBy(installedEffect, 1)
    return `≈ ${formatNumber(installedEffect, 1)} kWp`
  }

  const getActiveArea = () => formatNumber(active)

  const getPassiveArea = () => formatNumber(passive)

  const getEffectByDirection = (direction, format = true) => {
    const result = country[direction] * installedEffect
    if (!format) return roundBy(result, effectRoundBy)
    return `≈ ${formatNumber(result, effectRoundBy)} kWh`
  }

  const getInstallationCost = (format = true) => {
    const installationCostFactor = Number(country.installationCostFactor) || 1
    const productInstallationCostFactor = Number(product.installCostFactor) || 1
    const result =
      getDiscountFactor() *
      totalSize *
      installPrice *
      installationCostFactor *
      productInstallationCostFactor
    if (!format) return roundBy(result, priceRoundBy)
    return `≈ ${formatNumber(result, priceRoundBy)} €`
  }

  return {
    mToFt,
    isSquared,
    squareroot: isSquared ? "²" : "",
    activeSize,
    totalSize,
    effectFactor,
    installedEffect,
    getActiveArea,
    getInstallationCost,
    getPassiveArea,
    getTotalPrice,
    getInstalledEffect,
    getEffectByDirection,
    formatNumber,
  }
}
