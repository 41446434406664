import { urlFor } from "./sanity"

export const sanityLoader =
  (image, { aspect } = {}) =>
  ({ src: assetId, width, quality = 85 }) => {
    const build = urlFor(image || assetId)
      .width(width)
      .maxHeight(aspect && Math.round(width * aspect))
      .fit("crop")
      .quality(quality)
      .auto("format")

    return build.url()
  }
