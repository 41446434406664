import Modal from "react-modal"
import { useRouter } from "next/router"
import resolvePageProps from "../lib/resolvePageProps"
import { useEffect, useRef, useState } from "react"
import usePage from "../lib/usePage"
import { modalPageTypes } from "../lib/helpers"
import { AnimatePresence, motion } from "framer-motion"
import TitleBar from "./TitleBar"
import Loader from "./Loader"
import useNavigateToRoot from "../lib/useNavigateToRoot"

export default function () {
  const router = useRouter()
  const navigateToRoot = useNavigateToRoot()
  const {
    query,
    query: { slug = [] },
    locale,
  } = router

  const [data, setData] = useState()
  const [type, setType] = useState()
  const contentRef = useRef(null)

  const isOpen = modalPageTypes.map((t) => t.type).includes(slug[0])

  const handleAfterClose = () => {
    setType(null)
    setData(null)
  }

  const getAnimateFrom = () => {
    return modalPageTypes.find((t) => t.type === slug[0] || t.type === type)
      ?.animateFrom
  }

  const onContentRef = (node) => {
    contentRef.current = node
  }

  useEffect(() => {
    async function fetchData() {
      if (isOpen) {
        setType(slug[0])
        await resolvePageProps({
          preview: false,
          query,
          params: query,
          locale,
        }).then(setData)
        contentRef.current?.scrollTo({ top: 0, behavior: "smooth" })
      }
    }

    fetchData()
  }, [slug])

  return (
    <Modal
      contentRef={onContentRef}
      className={`ReactModal__Content scrollable-panel`}
      portalClassName={`ReactModal is-${type} from-${getAnimateFrom()}`}
      overlayClassName={`ReactModal__Overlay`}
      isOpen={isOpen}
      onRequestClose={navigateToRoot}
      closeTimeoutMS={500}
      onAfterClose={handleAfterClose}
    >
      <AnimatePresence>
        <ModalContent data={data} />
      </AnimatePresence>
    </Modal>
  )
}

const ModalContent = ({ data }) => {
  if (!data) return <Placeholder />
  const { globalSettings } = data
  const { TemplateComponent, page } = usePage({ data })
  const ref = useRef(null)

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      onAnimationComplete={() =>
        console.log(
          "test is completed",
          (ref.current.parentNode.style.transform = "none")
        )
      }
    >
      <TemplateComponent
        key={page._id}
        page={page}
        preview={false}
        globalSettings={globalSettings}
      />
    </motion.div>
  )
}

const Placeholder = () => {
  return (
    <div className="w-screen h-screen">
      <TitleBar></TitleBar>
      <motion.div className="absolute inset-0 flex justify-center items-center">
        <Loader />
      </motion.div>
    </div>
  )
}
