import { useRef, useEffect } from "react"
import { useState } from "react"
import PortableText from "react-portable-text"
import classNames from "classnames"
import { motion, AnimatePresence } from "framer-motion"

import { scrollRevealAnimation } from "../lib/animations"
import Image from "components/Image"

export default function Accordion({
  items = [],
  type = "default",
  onItemToggled,
  isClosable = true,
  openOnLoad = false,
  className = "",
}) {
  const [toggleState, setToggleState] = useState(
    items.map((item, i) => (openOnLoad && i === 0 ? true : false))
  )

  const handleToggle = (i, open) => {
    setToggleState((items) =>
      items.map((item, index) => {
        if (!isClosable && i === index) return true
        return index === i ? open : false
      })
    )
  }

  useEffect(() => {
    if (onItemToggled)
      onItemToggled(toggleState.findIndex((elem) => elem === true))
  }, [toggleState])

  return (
    <motion.div className={className} {...scrollRevealAnimation}>
      {items.map((item, i) => (
        <AccordionItem
          key={item._key}
          index={i}
          title={item.title}
          isOpen={toggleState[i]}
          onToggle={handleToggle}
          type={type}
        >
          {<AccordionContent item={item} type={type} />}
        </AccordionItem>
      ))}
    </motion.div>
  )
}

const AccordionContent = ({ item, type }) => {
  const { text, items } = item
  const serializers = {
    normal: (props) => (
      <p className="text-xl text-grayed md:pr-16 pb-4" {...props} />
    ),
    strong: (props) => (
      <strong className="text-primary font-normal" {...props} />
    ),
    navLink: (props) => <pre>{JSON.stringify(props, null, 10)}</pre>,
  }

  switch (type) {
    case "content":
      return (items || []).map((item, i) => (
        <div
          className="module-content-accordion--item pointer-events-none"
          key={item._key}
        >
          <div
            className={classNames("col-span-6 col-start-7 -mt-5 mb-10", {
              "border-t": i !== 0,
            })}
          >
            <h4 className="text-xl lg:text-2xl my-5">{item.title}</h4>
            <ul className="mb-6 pointer-events-auto">
              {(item.items || []).map((subitem) => {
                if (subitem.type === "file")
                  return (
                    <li className="mb-1" key={subitem._key}>
                      <a
                        href={subitem.file.asset.url}
                        className="flex items-center"
                      >
                        <img
                          src="/assets/icons/download.svg"
                          className="mr-2"
                        />
                        {subitem.title}
                      </a>
                    </li>
                  )
                return (
                  <li key={subitem._key}>
                    <p className="text-2xl">{subitem.title}</p>
                    <PortableText
                      content={subitem.text}
                      serializers={serializers}
                      className="text-content"
                    />
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      ))

    case "images":
      return (
        <div className="site-grid">
          {items.map(({ _key, text, image }) => (
            <div className="col-span-4 mb-8" key={_key}>
              <div className="image-container">
                <Image
                  src={image.asset._id}
                  alt={text}
                  className="image"
                  aspect={0.75}
                  sizes="(max-width: 640px) 100vw, 33vw"
                />
              </div>
              <PortableText
                content={text}
                className="pb-4 mt-4 text-content"
                serializers={serializers}
              />
            </div>
          ))}
        </div>
      )
    default:
      return (
        text && (
          <PortableText
            content={text}
            serializers={serializers}
            className="text-content"
          />
        )
      )
  }
}

export const AccordionItem = ({
  title,
  children,
  index,
  isOpen,
  onToggle,
  className = "",
}) => {
  const titleRef = useRef(null)

  const handleToggle = () => {
    const open = !isOpen
    const { current } = titleRef

    if (open && current) {
      setTimeout(() => {
        const { top, bottom } = current.getBoundingClientRect()
        const inViewport =
          top >= 0 &&
          bottom <=
            (window.innerHeight ||
              document.documentElement.clientHeight) /* or $(window).height() */
        if (!inViewport)
          current.scrollIntoView({
            behavior: "smooth",
          })
      }, 200)
    }

    onToggle(index, open)
  }

  return (
    <div
      className={classNames(
        "border-t border-grayed-light",
        { "is-active": isOpen },
        className
      )}
    >
      <h3
        ref={titleRef}
        className="text-3xl cursor-pointer flex justify-between items-center my-5 relative z-10"
        onClick={handleToggle}
      >
        <span>{title}</span>
        <div className="w-8 h-8 flex-shrink-0 select-none rounded-full bg-black flex justify-center items-center">
          <img
            src="/assets/icons/plus.svg"
            className={classNames(
              "w-[11px] h-[11px] transition-all duration-200 ease-in-out",
              {
                " rotate-[45deg]": isOpen,
              }
            )}
          />
        </div>
      </h3>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="accordion--content relative overflow-hidden pb-5"
            initial={{ height: 0, opacity: 0 }}
            animate={{ opacity: isOpen ? 1 : 0, height: isOpen ? "auto" : 0 }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ ease: "anticipate" }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
