import ModuleGallery from "../components/modules/ModuleGallery"
import ModuleHero from "../components/modules/ModuleHero"
import ModuleText from "../components/modules/ModuleText"
import ModuleTextImage from "../components/modules/ModuleTextImage"
import ModuleProjectText from "../components/modules/ModuleProjectText"
import ModuleTextAccordion from "../components/modules/ModuleTextAccordion"
import ModuleImageAccordion from "../components/modules/ModuleImageAccordion"
import ModuleContentAccordion from "../components/modules/ModuleContentAccordion"
import ModuleProductAccordion from "../components/modules/ModuleProductAccordion"
import ModuleProjects from "../components/modules/ModuleProjects"
import ModuleCTA from "../components/modules/ModuleCTA"
import ModuleScript from "../components/modules/ModuleScript"
import ModuleTimeline from "../components/modules/ModuleTimeline"
import ModuleStatistics from "../components/modules/ModuleStatistics"
import ModulePeople from "../components/modules/ModulePeople"
import ModuleQuote from "../components/modules/ModuleQuote"
import ModuleProcess from "../components/modules/ModuleProcess"
import ModuleNotFound from "../components/modules/ModuleNotFound"

export default {
  moduleGallery: ModuleGallery,
  moduleHero: ModuleHero,
  moduleText: ModuleText,
  moduleProjectText: ModuleProjectText,
  moduleTextAccordion: ModuleTextAccordion,
  moduleImageAccordion: ModuleImageAccordion,
  moduleContentAccordion: ModuleContentAccordion,
  moduleProductAccordion: ModuleProductAccordion,
  moduleProjects: ModuleProjects,
  moduleCTA: ModuleCTA,
  moduleScript: ModuleScript,
  moduleTextImage: ModuleTextImage,
  moduleTimeline: ModuleTimeline,
  moduleStatistics: ModuleStatistics,
  modulePeople: ModulePeople,
  moduleQuote: ModuleQuote,
  moduleProcess: ModuleProcess,
  moduleNotFound: ModuleNotFound,
}
