import * as Sentry from "@sentry/react"
import ErrorFallback from "./ErrorFallback"
import { motion as m } from "framer-motion"

export default function ModuleRenderer({
  page = {},
  modules = [],
  components = {},
  isRoot = false,
  globalSettings,
}) {
  return (
    <>
      {modules?.map((module) => {
        const Component =
          components[module._type] || components["moduleNotFound"]

        return (
          <Sentry.ErrorBoundary
            fallback={ErrorFallback(module)}
            key={module._key}
          >
            {!module.hidden && (
              <Component
                module={module}
                page={page}
                isRoot={isRoot}
                globalSettings={globalSettings}
              />
            )}
          </Sentry.ErrorBoundary>
        )
      })}
    </>
  )
}
