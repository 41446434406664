import { motion } from "framer-motion"
import { scrollRevealAnimation } from "../../lib/animations"

import NavLink from "components/NavLink"
import Image from "components/Image"

export default function ModuleTextImage({ module }) {
  const { title, text, link, image } = module

  return (
    <motion.div
      className="module-text-image container md:site-grid"
      {...scrollRevealAnimation}
    >
      {image && (
        <div className="col-span-6">
          <div className="image-container is-scaling">
            <Image
              src={image.asset._id}
              alt={title}
              className="image"
              sizes="(max-width: 640px) 100vw, 50vw"
            />
          </div>
        </div>
      )}
      <div className="col-span-6 border-t border-t-grayed-muted pt-5 lg:pr-32 module-text-image--text">
        <h3 className="text-4xl mb-3">{title}</h3>
        <p className="text-lg whitespace-pre-line">{text}</p>
        {link && <NavLink link={link} className="btn mt-10" />}
      </div>
    </motion.div>
  )
}
