import Accordion from "components/Accordion"

export default function ModuleImageAccordion({ module }) {
  const { items } = module
  if (items?.length === 0) return null
  return (
    <Accordion
      items={items}
      className="module-image-accordion container mt-28"
      type="images"
    />
  )
}
