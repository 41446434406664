import classNames from "classnames"
import { useTranslations } from "lib/useTranslations.client"

export default function FormField(props) {
  if (props.type == "select") return <Select {...props} />
  return <Input {...props} />
}

const Input = ({
  name,
  value = "",
  placeholder,
  onChange,
  required = false,
  type = "text",
  className = "",
}) => {
  const { t } = useTranslations()

  const isTextarea = type === "textarea"

  // Expand textarea automatically by number of rows
  const autoExpand = (field) => {
    field.style.height = "inherit"
    const computed = window.getComputedStyle(field)
    const height =
      parseInt(computed.getPropertyValue("border-top-width"), 10) +
      parseInt(computed.getPropertyValue("padding-top"), 10) +
      field.scrollHeight +
      parseInt(computed.getPropertyValue("padding-bottom"), 10) +
      parseInt(computed.getPropertyValue("border-bottom-width"), 10)
    field.style.height = height + "px"
  }

  return (
    <div className={classNames("mb-4 relative", className)}>
      {required && !value && (
        <div
          className={classNames(
            "border border-transparent absolute right-4 pointer-events-none text-xs flex items-center",
            isTextarea ? "bottom-4" : "inset-y-1"
          )}
        >{t("forms.required")}</div>
      )}
      {isTextarea ? (
        <textarea
          name={name}
          className="border border-grayed-muted px-4 py-2 rounded-3xl w-full text-lg resize-none"
          rows={5}
          onChange={onChange}
          onKeyUp={(e) => autoExpand(e.target)}
          defaultValue={value}
          placeholder={placeholder}
          required={required}
        />
      ) : (
        <input
          name={name}
          type={type}
          className="border border-grayed-muted px-4 py-2 rounded-full w-full text-lg"
          onChange={onChange}
          defaultValue={value}
          placeholder={placeholder}
          required={required}
        />
      )}
    </div>
  )
}
