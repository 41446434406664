import { useEffect, useState } from "react";
import classNames from "classnames";
import NextImage from "next/image";

import { sanityLoader } from "../lib/imageLoader";

export default function Image({
  src,
  alt,
  className = "",
  layout = "fill",
  objectFit = "cover",
  objectPosition = "center",
  onLoadingComplete = null,
  aspect,
  sizes,
  style,
}) {
  const [loaded, setLoaded] = useState(false);

  const handleLoadingComplete = () => {
    setLoaded(true);
    if (onLoadingComplete) onLoadingComplete();
  };

  useEffect(() => {}, [loaded]);

  return (
    <NextImage
      src={src}
      alt={alt}
      loader={sanityLoader(src, { aspect })}
      className={classNames("transition-opacity duration-500", className, {
        "opacity-0": !loaded,
      })}
      style={style}
      layout={layout}
      objectFit={objectFit}
      objectPosition={objectPosition}
      onLoadingComplete={handleLoadingComplete}
      sizes={sizes}
    />
  );
}
