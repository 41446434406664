import Head from "next/head"
import { useRouter } from "next/router"
import { fullUrlWithLocale, fullUrl } from "../lib/helpers"
import { urlFor } from "../lib/sanity"

export default function Helmet({ page, enabledLocales }) {
  const { asPath, locale, pathname} = useRouter()
  
  const hreflangs = enabledLocales
    .filter((lang) => lang !== locale)
    .map((lang) => ({
      lang,
      href: fullUrlWithLocale(asPath, lang),
    }))

  return (
    <Head>
      <title>{page.title} | Solarlab</title>
      <meta
        name="description"
        content={
          page.seoDescription || page.description || page.subtitle || "Solarlab"
        }
      />
      <meta name="og:title" content={page.seoTitle || page.title} />
      <meta name="og:url" content={fullUrl(asPath)} />
      <meta
        name="og:image"
        content={urlFor(page.seoImage?.asset || page.image?.asset).url()}
      />
      <meta name="og:site_name" content="Solarlab" />
      <meta
        name="og:description"
        content={page.seoDescription || page.description}
      />
      {page._type == "article" && (
        <>
          <meta name="og:type" content="movie" />
          <meta property="article:author" content={page.author?.name} />
          <meta property="article:published_time" content={page.publishedAt} />
        </>
      )}

      {hreflangs.map(({ href, lang }) => (
        <link rel="alternate" hrefLang={lang} href={href} key={lang} />
      ))}
      <link rel="alternate" hrefLang="x-default" href={fullUrl(asPath)} />
    </Head>
  )
}
