import Downloads from "../Downloads"
import Footer from "../Footer"
import Header from "../Header"
import Modals from "../Modals"
import Contact from "components/Contact"

export default function PageLayout({
  enabledLocales,
  globalSettings,
  fileGroups,
  isRoot,
  children,
}) {
  const hasGlobalSettings = globalSettings?.header && globalSettings?.footer

  return (
    <div className="layout">
      {hasGlobalSettings && (
        <Header
          navigation={globalSettings.header.navigation}
          notification={globalSettings.notificationsBar}
          isRoot={isRoot}
        />
      )}
      {children}
      {hasGlobalSettings && (
        <Footer
          globalSettings={globalSettings}
          enabledLocales={enabledLocales}
        />
      )}
      <Downloads globalSettings={globalSettings} fileGroups={fileGroups} />
      <Contact />
      <Modals />
    </div>
  )
}
