import { useSwiper } from "swiper/react"

export default function SwiperNavigation() {
  const swiper = useSwiper()
  return (
    <>
      <button
        className="w-8 h-8 flex-shrink-0 select-none rounded-full border border-grayed-light flex justify-center items-center"
        onClick={() => swiper.slidePrev()}>
        <img src="/assets/icons/arrow-left.svg" className="w-[16px] h-[16px]" />
      </button>
      <button
        className="w-8 h-8 flex-shrink-0 select-none rounded-full border border-grayed-light flex justify-center items-center"
        onClick={() => swiper.slideNext()}>
        <img
          src="/assets/icons/arrow-right.svg"
          className="w-[16px] h-[16px]"
        />
      </button>
    </>
  )
}
