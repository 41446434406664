import { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import { useTransform, useScroll, motion, isBrowser } from "framer-motion"

import HeroCarousel from "../HeroCarousel"

export default function ModuleHero({
  module,
  globalSettings: { timeBetweenSlides },
}) {
  const { title, ctaText, media: { type, gallery, video } = {} } = module || {}
  const [initiated, setInitiated] = useState(false)
  const videoEl = useRef(null)

  const transition = "transform !transition-all duration-1000 ease-in-out"

  const { scrollY } = useScroll()
  const bgY = useTransform(
    scrollY,
    [0, isBrowser ? window.innerHeight : 0],
    [0, isBrowser ? window.innerHeight : 0]
  )

  const scrollDown = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    if (videoEl.current) videoEl.current.load()
  }, [module])

  return (
    <div className="module-hero relative h-wh overflow-hidden bg-secondary">
      <motion.div className="container absolute inset-0 z-10">
        <div
          className={classNames(
            "lg:site-grid relative z-50 opacity-0",
            transition,
            {
              "opacity-100": initiated,
            }
          )}
        >
          <div className="col-span-full col-start-7">
            <p className="text-4xl md:text-7xl lg:text-6xl mt-28 lg:mt-6 mr-6 text-white mb-10 whitespace-pre-line">
              {title}
            </p>
            {ctaText && (
              <button className="btn btn-inverted" onClick={scrollDown}>
                {ctaText}
              </button>
            )}
          </div>
        </div>
        <div
          style={{
            transitionDelay: "1000ms !important",
            transitionDuration: "2000ms !important",
          }}
          className={classNames(
            transition,
            "absolute left-0 bottom-0 h-[200vh] w-screen z-40 origin-bottom-right bg-secondary delay-1000 -rotate-[8deg] ",
            {
              "-translate-y-full ": initiated,
            }
          )}
        >
          <div
            className={classNames(
              transition,
              "w-full h-full absolute left-full transform -translate-x-1 delay-200 bg-secondary"
            )}
          ></div>
          <div
            className={classNames(
              transition,
              "w-full h-full absolute right-full transform translate-x-1 delay-200 bg-secondary"
            )}
          ></div>
        </div>
      </motion.div>
      <motion.div
        className="absolute top-0 left-0 w-screen h-screen"
        style={{ y: bgY }}
      >
        {type === "video" && (
          <video
            ref={videoEl}
            className="object-cover object-center w-full h-full"
            autoPlay
            muted
            loop
            playsInline
            onCanPlay={() => {
              setInitiated(true)
            }}
            src={video.asset.url}
          />
        )}
        {type === "gallery" && (
          <div className="w-full h-full">
            <HeroCarousel
              images={gallery}
              timeBetweenSlides={timeBetweenSlides}
              onLoadingComplete={() => setInitiated(true)}
            />
          </div>
        )}
      </motion.div>
    </div>
  )
}
