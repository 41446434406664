import { useState, useRef, useEffect } from "react"
import classNames from "classnames"
import { formatProjectType } from "lib/helpers"
import PortableText from "react-portable-text"
import { Swiper, SwiperSlide } from "swiper/react"

import modules from "../../config/modules"

import ModuleRenderer from "../ModuleRenderer"
import TitleBar from "components/TitleBar"
import SwiperNavigation from "components/SwiperNavigation"
import NavLink from "components/NavLink"
import Image from "components/Image"
import Loader from "../Loader"
import { useTranslations } from "lib/useTranslations.client"

export default function ProjectTemplate({ page = {}, preview, children }) {
  const { t } = useTranslations()

  const {
    modules: pageModules,
    description,
    title,
    type,
    image,
    architect,
    location,
    effect,
    otherProjects,
  } = page

  const [scrolledPastTitle, setScrolledPastTitle] = useState(false)
  const titleRef = useRef(null)
  const titleBarRef = useRef(null)

  const serializers = {
    normal: (props) => <p className="text-xl leading-7 mb-4" {...props} />,
    strong: (props) => (
      <strong className="text-primary font-normal" {...props} />
    ),
    // navLink: (props) => <pre>{JSON.stringify(props, null, 10)}</pre>,
  }

  const handleScroll = () => {
    const { top: tT, height: tH } = titleRef.current.getBoundingClientRect()
    const bH = titleBarRef.current.getBoundingClientRect().height
    const sp = document.querySelector(".scrollable-panel")
    setScrolledPastTitle(sp?.scrollTop - bH > tT + tH)
  }

  useEffect(() => {
    const sp = document.querySelector(".scrollable-panel")
    if (sp) sp.addEventListener("scroll", handleScroll)
    handleScroll()
    return () => sp?.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <div className="pb-8">
      <TitleBar ref={titleBarRef}>
        <div className="relative">
          <div
            className={classNames("transform transition-all duration-150", {
              "-translate-y-full opacity-0": scrolledPastTitle,
            })}
          >
            {t("general.project")}
          </div>
          <div
            ref={titleRef}
            className={classNames(
              "absolute top-full left-0 -translate-y-full",
              "transform transition-all duration-200",
              {
                "translate-y-full opacity-0": !scrolledPastTitle,
              }
            )}
          >
            {title}{" "}
            <span className="text-grayed">{formatProjectType(type)}</span>
          </div>
        </div>
      </TitleBar>
      <div className="container pt-8">
        <h1 className="mb-20 lg:mb-40 text-4xl md:text-5xl lg:text-6xl">
          {title}
          <br />
          <span className="text-gray-400">{formatProjectType(type)}</span>
        </h1>
      </div>
      <div className="relative h-screen flex items-center justify-center bg-secondary-light">
        <Loader small light />
        <Image
          src={image.asset._id}
          alt={title}
          sizes="(max-width: 640px) 100vw, 80vw"
        />
      </div>
      <div className="container">
        <div className="md:grid grid-cols-3 gap-8 mt-10 mb-40">
          <div className="col-span-1">
            <p className="mb-2">
              Architect
              <br />
              <span className="text-gray-400">{architect}</span>
            </p>
            <p className="mb-2">
              Location
              <br />
              <span className="text-gray-400">{location}</span>
            </p>
            <p className="mb-2">
              Effect
              <br />
              <span className="text-gray-400">{effect} Wp</span>
            </p>
          </div>
          <div className="col-span-2">
            {description && (
              <PortableText
                content={description}
                serializers={serializers}
                className="text-content"
              />
            )}
          </div>
        </div>
      </div>
      {pageModules ? (
        <ModuleRenderer
          page={page}
          modules={pageModules}
          preview={preview}
          components={modules}
        />
      ) : (
        children
      )}
      {otherProjects.length > 1 && (
        <div className="border-t border-t-grayed">
          <div className="container pt-4 lg:pt-8">
            <h2 className="text-3xl md:text-4xl lg:text-5xl">Explore more</h2>
          </div>
          <Swiper
            loop
            speed={2500}
            autoplay={{
              delay: -10,
            }}
            spaceBetween={16}
            slidesPerView={1.1}
            breakpoints={{
              768: {
                slidesPerView: 2.5,
              },
              1024: {
                slidesPerView: 2.5,
                spaceBetween: 32,
              },
            }}
            className="mt-24 !px-4 md:!px-8"
          >
            {otherProjects.map(({ title, type, image, slug }) => {
              return (
                <SwiperSlide>
                  <NavLink
                    link={{
                      linkTo: { slug },
                    }}
                  >
                    <>
                      <span className="block relative pt-[56%] mb-5">
                        <Image
                          src={image.asset._id}
                          alt={title}
                          sizes="(max-width: 640px) 100vw, 33vw"
                        />
                      </span>
                      <p>{title}</p>
                      <p className="text-grayed">{formatProjectType(type)}</p>
                    </>
                  </NavLink>
                </SwiperSlide>
              )
            })}
            <div
              slot="container-start"
              className="flex justify-end mb-10 gap-2"
            >
              <SwiperNavigation />
            </div>
          </Swiper>
        </div>
      )}
    </div>
  )
}
