import classNames from "classnames"
import { forwardRef } from "react"
import useNavigateToRoot from "../lib/useNavigateToRoot"
import { useTranslations } from "lib/useTranslations.client"

const TitleBar = forwardRef(function (
  { children, close, onClose, progress, className },
  ref
) {
  const { t } = useTranslations()

  const navigateToRoot = useNavigateToRoot()

  const handleClose = (e) => {
    onClose ? onClose(e) : navigateToRoot()
  }

  return (
    <div
      className={classNames(
        "sticky top-0 inset-x-0 flex flex-shrink-0 whitespace-nowrap justify-between border-b border-b-grayed-muted bg-white z-header px-4 lg:px-8 py-4 text-base overflow-hidden",
        className
      )}
      ref={ref}>
      {progress > 0 && (
        <div
          className="absolute z-10 top-full left-0 bg-black h-[1px]"
          style={{
            transition: "width 200ms ease-out",
            width: `${progress * 100}%`,
          }}
        />
      )}
      <div>{children}</div>
      {close ? (
        close
      ) : (
        <button className="underline" onClick={handleClose}>
          {t("general.close")}
        </button>
      )}
    </div>
  )
})

export default TitleBar
