export default function ModuleQuote({ module }) {
  const { quote, client, jobtitle } = module
  return (
    <div className="container site-grid my-32">
      <div className="col-span-8 col-start-5 pl-8 md:pl-0">
        <p className="relative text-xl md:text-2xl italic mb-8 whitespace-pre-line">
          <img
            src="/assets/icons/quote.svg"
            className="absolute right-full top-2 pr-3"
          />
          {quote}
        </p>
        <p>{client}</p>
        <p className="text-grayed">{jobtitle}</p>
      </div>
    </div>
  )
}
