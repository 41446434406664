import { useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import { formatProjectType } from "lib/helpers"
import { Swiper, SwiperSlide, useSwiper } from "swiper/react"

import NavLink from "../NavLink"
import SwiperNavigation from "components/SwiperNavigation"
import Image from "components/Image"
import { scrollSlideInAnimation } from "../../lib/animations"
import { Autoplay } from "swiper"

export default function ModuleProjects({ module }) {
  const { projects, pinned, max } = module
  const [activeIndex, setActiveIndex] = useState(0)
  const maxProjects =
    max > projects.length ? projects.length : max || projects.length
  const pinnedProjects = (pinned || []).map(({ slug }) =>
    projects.find((project) => project.slug.current === slug.current)
  )
  const sortedProjects = [
    ...pinnedProjects,
    ...projects.filter((project) => !pinnedProjects.includes(project)),
  ].splice(0, maxProjects)

  return (
    <div className="overflow-x-hidden mb-32">
      <motion.div {...scrollSlideInAnimation}>
        <Swiper
          spaceBetween={16}
          slidesPerView={1.1}
          breakpoints={{
            1024: {
              slidesPerView: 1.5,
              spaceBetween: 32,
            },
          }}
          className="mt-16 !px-4 lg:!px-8"
          onSlideChange={({ activeIndex }) => setActiveIndex(activeIndex)}
        >
          {sortedProjects.map(
            ({ _id, title, image, slug }) =>
              image && (
                <SwiperSlide className="relative" key={_id}>
                  <NavLink
                    link={{ linkTo: { slug } }}
                    className="block relative pt-[56%]"
                  >
                    <Image
                      src={image.asset._id}
                      alt={title}
                      aspect={0.56}
                      sizes="(max-width: 640px) 100vw, 66vw"
                    />
                  </NavLink>
                </SwiperSlide>
              )
          )}
          <SwiperContainerEnd items={sortedProjects} />
        </Swiper>
      </motion.div>
    </div>
  )
}

const SwiperContainerEnd = ({ items }) => {
  const swiper = useSwiper()
  const cur = swiper.activeIndex
  const { title, type } = items[cur]
  const direction = swiper.previousIndex >= swiper.activeIndex ? -1 : 1

  return (
    <div slot="container-end" className="site-grid text-2xl pt-5">
      <div className="col-span-8 flex justify-between relative">
        <AnimatePresence>
          <motion.div
            key={cur}
            initial={{ opacity: 0, x: direction * 40 }}
            animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
            exit={{ opacity: 0, position: "absolute" }}
          >
            <p>{title}</p>
            <p className="text-grayed">{formatProjectType(type)}</p>
          </motion.div>
        </AnimatePresence>
        <div className="whitespace-nowrap relative">
          <AnimatePresence>
            <div className="inline-block">
              <motion.div
                key={cur}
                initial={{ opacity: 0, y: direction * 10 }}
                animate={{ opacity: 1, y: 0, transition: { delay: 0.1 } }}
                exit={{ opacity: 0, position: "absolute" }}
              >
                {cur + 1}
              </motion.div>
            </div>
          </AnimatePresence>
          <div className="inline-block">
            <span className="inline-block ml-2 mr-1">/</span>
            {items.length}
          </div>
        </div>
      </div>
      <div className="col-span-4 flex justify-end gap-2">
        <SwiperNavigation />
      </div>
    </div>
  )
}
