import { useInView } from "framer-motion"
import { useEffect, useRef } from "react"
import Counter from "../Counter"

const Stat = ({ number }) => {
  const ref = useRef()
  const isInView = useInView(ref, { once: true })

  useEffect(() => {}, [isInView])

  return (
    <h3 className="text-6xl md:text-8xl mb-3" ref={ref}>
      {isInView && <Counter to={number} duration={1.5} />}
    </h3>
  )
}

export default function ModuleStatistics({ module }) {
  const { label, items } = module

  return (
    <div className="container md:site-grid border-t border-t-grayed-muted mt-60">
      <div className="md:col-span-4 lg:col-span-6 pt-5">{label}</div>
      <div className="md:col-span-8 lg:col-span-6">
        {items.map((item, i) => (
          <div className="first:border-0 border-t pt-5 pb-10" key={i}>
            <Stat number={item.statistic} />
            <p>{item.subtitle}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
