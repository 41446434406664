import { useState } from "react"
import axios from "axios"
import FormField from "./FormField"
import Sidebar from "./Sidebar"
import Link from "next/link"
import { useTranslations } from "lib/useTranslations.client"

export default function Contact() {
  const { t } = useTranslations()

  const initialInputs = { name: "", email: "", message: "" }

  const [isOpen, setIsOpen] = useState(false)
  const [inputs, setInputs] = useState(initialInputs)
  const [status, setStatus] = useState(false)

  const closeModal = () => {
    setIsOpen(false)
  }

  const onInputChange = (e) => {
    setInputs((input) => ({
      ...input,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    axios
      .post("/api/contact-form", inputs)
      .then((res) => {
        e.target.reset()
        setInputs(initialInputs)
        setStatus("SUCCESS")
      })
      .catch((e) => {
        setStatus("ERROR")
        console.log(e)
      })
  }

  return (
    <div>
      <button
        className="btn btn-primary fixed bottom-4 lg:bottom-8 right-4 lg:right-8 z-contact-button"
        onClick={() => setIsOpen((o) => !o)}
      >
        {t('general.contact-us')}
      </button>
      <Sidebar isOpen={isOpen} onClose={closeModal}>
        <div className="p-4 lg:p-8">
          <h2 className="text-2xl mb-10">Contact us to learn more</h2>
          <form onSubmit={onSubmit}>
            <FormField
              name="name"
              placeholder={t('forms.name')}
              onChange={onInputChange}
              value={inputs.name}
              required
            />
            <FormField
              name="email"
              placeholder={t('forms.email')}
              type="email"
              onChange={onInputChange}
              value={inputs.email}
              required
            />
            <FormField
              name="message"
              type="textarea"
              placeholder={t('forms.contact-message')}
              onChange={onInputChange}
              value={inputs.message}
              required
            />
            {status === "SUCCESS" && (
              <p>{t('forms.contact-success')}</p>
            )}
            {status === "ERROR" && (
              <p>
                {t('forms.error')}
              </p>
            )}


            <button type="submit" className="btn btn-primary mt-10">
            {t('forms.submit')}
            </button>
            <span className="mx-4">{t('general.or')}</span>
            <Link href="/calculator">
              <a className="btn btn-secondary">{t('general.calculate-estimate')}</a>
            </Link>
          </form>
        </div>
      </Sidebar>
    </div>
  )
}
