export const locales = {
  en: "English",
  de: "German",
  fr: "French",
  es: "Spanish",
  nl: "Dutch",
  it: "Italian",
  sv: "Swedish",
  nb: "Norwegian",
}

export const localeCodes = Object.keys(locales)
