import Modal from "react-modal"
import TitleBar from "./TitleBar"

export default function Sidebar({ children, title, isOpen, onClose }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={`ReactModal__Content`}
      portalClassName={`ReactModal is-sidebar from-right`}
      overlayClassName={`ReactModal__Overlay`}
      closeTimeoutMS={800}>
      <TitleBar onClose={onClose}>{title}</TitleBar>
      {children}
    </Modal>
  )
}
