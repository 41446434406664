import { Autoplay, Mousewheel } from "swiper"
import { useSwiper, Swiper, SwiperSlide } from "swiper/react"
import { motion } from "framer-motion"
import classNames from "classnames"

import "swiper/css"
import "swiper/css/navigation"
import SwiperNavigation from "components/SwiperNavigation"

import Image from "components/Image"
import { scrollRevealAnimation } from "../../lib/animations"
import { useEffect, useState } from "react"

export default function ModuleGallery({ module }) {
  const { layout, images } = module
  if (images.length === 0) return null

  const [swiperMoved, setSwiperMoved] = useState()

  const gridClassnames = (i) => {
    if (i % 3 === 0) return "col-span-4 lg:col-span-3 lg:col-start-4"
    if (i % 3 === 1) return "col-span-8 lg:col-span-6"
    return "col-span-full lg:col-span-9"
  }

  const gridImageSizes = (i) => {
    if (i % 3 === 0)
      return `(max-width: 640px) 100vw,
              33vw`
    if (i % 3 === 1)
      return `(max-width: 640px) 100vw,
              50vw`
    return `(max-width: 640px) 100vw,
              66vw`
  }

  switch (layout) {
    case "grid":
      return (
        <div className="container flex flex-col md:site-grid mt-28 gap-y-20">
          {images.map(({ _key, image, imageAlt }, i) => (
            <motion.div
              className={gridClassnames(i)}
              key={_key}
              {...scrollRevealAnimation}
            >
              <div className="image-container is-scaling">
                <Image
                  src={image.asset._id}
                  alt={imageAlt}
                  className="image"
                  sizes={gridImageSizes(i)}
                  aspect={1}
                />
              </div>
              <ImageAlt text={imageAlt} />
            </motion.div>
          ))}
        </div>
      )
    case "row":
      return (
        <div
          className={classNames("container site-grid", {
            "px-0": images.length < 2,
          })}
        >
          {images.map(({ _key, image, imageAlt }, i) => (
            <motion.div
              key={_key}
              className={`col-span-${
                images.length > 1 ? 12 / images.length : "full"
              }`}
              {...scrollRevealAnimation}
            >
              <div className="image-container is-scaling">
                <Image
                  src={image.asset._id}
                  alt={imageAlt}
                  className="image"
                  sizes={`(max-width: 640px) 100vw, ${Math.round(
                    100 / images.length
                  )}vw`}
                />
              </div>
              <ImageAlt
                text={imageAlt}
                className={`mb-8 md:mb-0 px-4 ${
                  images.length > 1 ? "md:px-0" : ""
                }`}
              />
            </motion.div>
          ))}
        </div>
      )
    default: // Slider
      return (
        <div className="overflow-x-hidden">
          <Swiper
            loop
            autoplay={{ delay: -10 }}
            speed={swiperMoved ? 300 : 5000}
            modules={[Autoplay]}
            mousewheel={true}
            spaceBetween={16}
            slidesPerView={1.4}
            centeredSlides={false}
            breakpoints={{
              768: {
                slidesPerView: 2.4,
              },
              1024: {
                spaceBetween: 32,
                slidesPerView: 3.4,
                centeredSlides: true,
              },
            }}
            className={classNames("!px-4 lg:!px-8 swiper is-autoplaying", {
              "is-swiper-moved": swiperMoved,
            })}
            onSliderFirstMove={() => setSwiperMoved(true)}
          >
            {images.map(({ _key, image, imageAlt }) => (
              <SwiperSlide key={_key}>
                <div className="image-container is-scaling">
                  <Image
                    src={image.asset._id}
                    alt={imageAlt}
                    className="image"
                    sizes="(max-width: 640px) 100vw, 33vw"
                    aspect={1.3}
                  />
                </div>
                <ImageAlt text={imageAlt} />
              </SwiperSlide>
            ))}
            <div
              slot="container-start"
              className="container flex justify-end mb-10 gap-2"
              onMouseDown={() => setSwiperMoved(true)}
              onTouchStart={() => setSwiperMoved(true)}
            >
              <AutoplayConfig moved={swiperMoved} />
              <SwiperNavigation />
            </div>
          </Swiper>
        </div>
      )
  }
}

const ImageAlt = ({ text, className }) => {
  return <p className={classNames("text-gray-400 mt-3", className)}>{text}</p>
}

const AutoplayConfig = ({ moved }) => {
  const swiper = useSwiper()

  useEffect(() => {
    if (moved) swiper.autoplay.stop()
  }, [moved])

  return null
}
