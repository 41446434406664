import { useRouter } from "next/router"

function useShallowRoute({ onClick } = {}) {
  const router = useRouter()

  const changeShallowRoute = (e) => {
    e.preventDefault()
    router.push(
      {
        pathname: e.currentTarget.href,
      },
      undefined,
      { shallow: true }
    )
    if (onClick) onClick()
  }

  return {
    changeShallowRoute,
  }
}

export default useShallowRoute
