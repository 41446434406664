import classNames from "classnames"
import { motion, AnimatePresence, isBrowser } from "framer-motion"
import kebabCase from "lodash.kebabcase"
import Link from "next/link"
import { useEffect, useState } from "react"

import NavLink from "./NavLink"
import NotificationBar from "./NotificationBar"
import { set } from "date-fns"

export default function Header({ navigation, isRoot, notification }) {
  const [navLight, setNavLight] = useState(false)
  // Nav has 3 states: hidden, visible, expanded
  const [navState, setNavState] = useState("hidden")
  const navExpanded = navState === "expanded"
  const navVisible = navState !== "hidden"
  const [mobileNavOpen, setMobileNavOpen] = useState(false)

  const navHashes = navigation
    .map((item) => kebabCase(item.label))
    .filter((item) => item !== "")

  const [selected, setSelected] = useState(0)
  const [initiated, setInitiated] = useState(false)

  useEffect(() => {
    document.body.classList.toggle("overflow-hidden", mobileNavOpen)
  }, [mobileNavOpen])

  useEffect(() => {
    if (isBrowser) {
      setInitiated(true)

      let currentScroll = Math.max(0, window.scrollY)

      function handleScroll() {
        const scroll = Math.max(0, window.scrollY)

        if (scroll < 100) {
          setNavState("hidden")
        } else if (scroll < currentScroll - 10) {
          setNavState("expanded")
        } else if (scroll > currentScroll) {
          setNavState("visible")
        }
        currentScroll = scroll

        setNavLight(
          document.querySelector(".module-hero") &&
            currentScroll < window.innerHeight
        )

        let continueLoop = true
        Array.from(document.querySelectorAll("div[id]"))
          .filter((el) => navHashes.includes(el.id))
          .reverse()
          .forEach((el) => {
            const { y } = el.getBoundingClientRect()
            if (y < 10 && continueLoop) {
              const index = navHashes.indexOf(el.id)
              setSelected(index)
              continueLoop = false
            }
          })
      }

      handleScroll()

      window.addEventListener("scroll", handleScroll)

      return () => {
        handleScroll()
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  return (
    <div>
      <NotificationBar notification={notification} />
      <div className="fixed top-0 left-0 w-full z-header pointer-events-none transition-opacity delay-500 duration-1000 mix-blend-exclusion">
        <div className="container relative">
          <Link href="/">
            <a className="absolute z-logo top-4 left-4 lg:top-6 lg:left-8 pointer-events-auto logo">
              <img
                src="/assets/logo-white.svg"
                alt="Logo"
                className="w-[190px]"
              />
            </a>
          </Link>
        </div>
      </div>
      <div
        className={classNames(
          "fixed lg:hidden top-0 left-0 w-screen h-screen bg-secondary z-header transform transition-transform duration-300",
          { "translate-x-full": !mobileNavOpen }
        )}
      ></div>
      <header
        className={classNames(
          "header fixed top-0 left-0 w-full z-header pointer-events-none transition-opacity delay-500 duration-1000 mix-blend-exclusion text-white",
          { "opacity-0": !initiated }
        )}
      >
        <div className="container relative">
          {/* Mobile menu */}
          <div className="block absolute z-mobile-nav top-0 left-0 w-full lg:hidden pointer-events-auto">
            <button
              className="absolute z-10 top-0 right-0 w-14 p-4"
              onClick={() => setMobileNavOpen((open) => !open)}
            >
              <span
                className={classNames(
                  "block h-[2px] bg-white mb-1 transition-all duration-100 transform",
                  { "scale-0": mobileNavOpen }
                )}
              />
              <span
                className={classNames(
                  "block h-[2px] bg-white mb-1 transition-all duration-100 transform",
                  { "rotate-45 translate-y-[3px]": mobileNavOpen }
                )}
              />
              <span
                className={classNames(
                  "block h-[2px] bg-white mb-1 transition-all duration-100 transform",
                  { "-rotate-45 translate-y-[-3px]": mobileNavOpen }
                )}
              />
              <span
                className={classNames(
                  "block h-[2px] bg-white mb-1 transition-all duration-100 transform",
                  { "scale-0": mobileNavOpen }
                )}
              />
            </button>
            <div
              className={classNames(
                "absolute top-0 left-0 w-screen h-screen flex items-center transform transition-transform duration-300",
                { "translate-x-full": !mobileNavOpen }
              )}
            >
              <ul className="container text-3xl">
                {navigation.map((link, i) => {
                  const isSelected = selected === i
                  return (
                    <li
                      key={link._key}
                      className={classNames(
                        {
                          "opacity-50 pointer-events-auto hover:opacity-100":
                            !isSelected,
                        },
                        { "opacity-100 pointer-events-auto": isSelected }
                      )}
                      onClick={() => setMobileNavOpen(false)}
                    >
                      <NavLink link={link} className="inline-block py-1" />
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          {/* Desktop menu */}
          <AnimatePresence>
            {isRoot && (
              <motion.nav
                className="hidden lg:site-grid pointer-events-none"
                initial={{ opacity: 0 }}
                animate={{ opacity: navVisible ? 1 : 0 }}
                exit={{ opacity: 0 }}
              >
                <ul
                  className="col-start-4 col-span-2 pt-8"
                  onMouseOver={() => setNavState("expanded")}
                  onMouseOut={() => setNavState("visible")}
                >
                  {navigation.map((link, i) => {
                    const isSelected = selected === i
                    return (
                      <li
                        key={link._key}
                        className={classNames(
                          "header--nav--item transition-all",
                          { "pointer-events-auto": navExpanded || isSelected },
                          {
                            "opacity-0": !navExpanded && !isSelected,
                          },
                          {
                            "opacity-50 hover:opacity-100":
                              navExpanded && !isSelected,
                          },
                          { "opacity-100": isSelected }
                        )}
                        style={
                          !navExpanded
                            ? { transform: `translateY(-${20 * selected}px)` }
                            : {}
                        }
                      >
                        <NavLink link={link} />
                      </li>
                    )
                  })}
                </ul>
              </motion.nav>
            )}
          </AnimatePresence>
        </div>
      </header>
    </div>
  )
}
