import React from "react"
import classNames from "classnames"

export default function Loader({ small = false, light = false, className }) {
  return (
    <div className={classNames("loader-border", { "is-sm": small }, className)}>
      <div
        className={classNames("loader-core", { "bg-secondary-light": light })}
      />
    </div>
  )
}
