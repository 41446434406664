import { Fragment, useEffect, useState } from "react"
import axios from "axios"
import FormField from "./FormField"
import Sidebar from "./Sidebar"
import { useRouter } from "next/router"
import { useMemo } from "react"
import classNames from "classnames"
import { useTranslations } from "lib/useTranslations.client"

export default function Downloads({
  fileGroups,
  globalSettings: { downloadSettings },
}) {
  const { t } = useTranslations()

  const initialInputs = { email: "" }

  const [isOpen, setIsOpen] = useState(false)
  const [inputs, setInputs] = useState(initialInputs)
  const [status, setStatus] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])

  const fileGroupsMemo = useMemo(() => {
    return fileGroups.map((fileGroup) => {
      return {
        ...fileGroup,
        files: fileGroup.files.map(({ title, file, _key }) => {
          return {
            _key,
            title,
            url: file.asset.url,
            isSelected: selectedFiles.includes(_key),
          }
        }),
      }
    })
  }, [fileGroups, selectedFiles])

  const router = useRouter()
  const onInputChange = (e) => {
    setInputs((input) => ({
      ...input,
      [e.target.name]: e.target.value,
    }))
  }

  useEffect(() => {
    const onHashChangeStart = () => {
      setIsOpen(window.location.hash.indexOf("#/downloads") > -1)
    }

    window.addEventListener("hashchange", onHashChangeStart)
    onHashChangeStart()

    return () => window.removeEventListener("hashchange", onHashChangeStart)
  }, [])

  const onSubmit = (e) => {
    e.preventDefault()
    setStatus("LOADING")

    const files = fileGroupsMemo
      .map((fileGroup) => fileGroup.files)
      .flat(Infinity)
      .filter((file) => file.isSelected)
      .map((file) => {
        return {
          title: file.title,
          url: file.url,
        }
      })

    axios
      .post("/api/download-files", {
        ...inputs,
        files,
      }, {
        headers: {
          'Accept-Language': router.locale
        }
      })
      .then((res) => {
        e.target.reset()
        // setSelectedFiles([])
        setInputs(initialInputs)
        setStatus("SUCCESS")
      })
      .catch((e) => {
        setStatus("ERROR")
        console.log(e)
      })
  }

  const closeModal = () => {
    router.push("/", undefined, { shallow: true })
    setIsOpen(false)
  }

  const onClickCheck = (fileKey) => {
    if (selectedFiles.includes(fileKey)) {
      setSelectedFiles(selectedFiles.filter((file) => file !== fileKey))
      return
    }

    setSelectedFiles([...selectedFiles, fileKey])
  }

  return (
    <Sidebar isOpen={isOpen} onClose={closeModal}>
      <div className="p-4 lg:p-8">
        <h2 className="text-2xl mb-8">Download tools and guides</h2>
        <form onSubmit={onSubmit}>
          {fileGroupsMemo.map(({ _id, title, files }) => (
            <div key={_id} className="border-t border-secondary pt-6 my-6">
              <h3 className="text-xl mb-2">{title}</h3>
              {files.map(({ _key, title, url, isSelected }) => (
                <Fragment key={_key}>
                  {status === "SUCCESS" && isSelected ? (
                    // <a
                    //   href={url}
                    //   target="_blank"
                    //   className="flex items-center py-2 underline"
                    // >
                    //   <img
                    //     src="/assets/icons/download.svg"
                    //     className="mr-2 w-[13px]"
                    //   />
                    title
                  ) : (
                    // </a>
                    <label className="py-2 flex items-start">
                      <input
                        type="checkbox"
                        className="mr-2 mt-1"
                        checked={isSelected}
                        disabled={status === "SUCCESS" && !isSelected}
                        onChange={() => onClickCheck(_key)}
                      />
                      {title}
                    </label>
                  )}
                </Fragment>
              ))}
            </div>
          ))}
          <FormField
            name="email"
            placeholder={t("forms.email")}
            type="email"
            onChange={onInputChange}
            value={inputs.email}
            className="mb-0 mr-4 grow"
            required
          />
          <div className="mt-4 mb-10 whitespace-pre-line">
            {status === "SUCCESS" && downloadSettings.formConfirmationText}
            {status === "ERROR" && (
              <p>
                {t('forms.error')}
              </p>
            )}
          </div>

          <button
            type="submit"
            className={classNames("btn btn-primary grow-0", {
              "opacity-80 pointer-events-none": status === "LOADING",
            })}
          >
            {downloadSettings.downloadButtonLabel}
          </button>
        </form>
      </div>
    </Sidebar>
  )
}
