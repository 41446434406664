import { useState } from "react";
import classNames from "classnames";

import Image from "components/Image";

export default function ModulePeople({ module }) {
  const { items } = module;

  return (
    <div className="container md:site-grid mt-32">
      {items.map((item, i) => (
        <Person key={i} item={item} />
      ))}
    </div>
  );
}

const Person = ({ item }) => {
  const { name, jobtitle, text, image } = item;
  const [open, setOpen] = useState(false);
  return (
    <div className="col-span-6 lg:col-span-4">
      <div className="pt-[143%] relative mb-4">
        <Image
          src={image.asset._id}
          alt={name}
          sizes="(max-width: 640px) 100vw, (max-width: 1024px) 50vw, 33vw"
        />
        <div
          className={classNames(
            "absolute bottom-0 inset-x-0 bg-secondary-light p-5 pb-10",
            { "top-0": open }
          )}
        >
          <h3 className="text-xl mb-3">
            {name}
            <br />
            <span className="text-grayed">{jobtitle}</span>
          </h3>
          {open && <p className="whitespace-pre-line">{text}</p>}
          <button
            className="underline p-0 bg-transparent absolute border-0 block bottom-5 left-5"
            onClick={() => setOpen((o) => !o)}
          >
            Read {open ? "less" : "more"}
          </button>
        </div>
      </div>
    </div>
  );
};
