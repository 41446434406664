export const scrollRevealAnimation = {
  initial: { opacity: 0 },
  whileInView: { opacity: 1, translateY: 0 },
  viewport: { once: true, margin: "0px 0px -20% 0px" },
}

export const scrollSlideInAnimation = {
  transition: { duration: 1, ease: "easeInOut" },
  initial: { opacity: 0, translateX: "50vw" },
  whileInView: { opacity: 1, translateX: 0 },
  viewport: { once: true, margin: "0px 0px -20% 0px" },
}
