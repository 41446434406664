import { groq } from "next-sanity"

export const slugPathsQuery = groq`*[defined(slug)].slug.current`

export const imageFragment = groq`
    crop,
    hotspot,
    asset-> {
        _id,
        crop,
        hotspot,
        metadata {
            lqip,
            dimensions {
                width, height, aspectRatio
            },
        }
    }
`

export const mediaFragment = groq`
    type,
    video {
        asset ->{
            _id,
            url
        }
    },
    gallery[] {
        ${imageFragment}
    }
`

const fileFragment = groq`
    asset-> {
        ...
    }
`

function getNavLinkFragment(locale) {
  return groq`
    _key,
    "label": ${getIntlFieldFragment("label", locale)},
    url,
    external,
    linkTo->{
        ...,
        "title": ${getIntlFieldFragment("title", locale)},
        slug {
            ...,
            "current": 
                select(
                    current == "home" => "",
                    current
                ),
        },
    },
    deeplink,
    "hash": coalesce(
        *[slug.current == ^.linkTo->slug.current][0].modules[_key == ^.deeplink][0].label[_key == 'en'].value,
        *[slug.current == ^.linkTo->slug.current][0].modules[_key == ^.deeplink][0]._key,
    ),
`
}

function getDefaultModuleFragments(locale) {
  return {
    moduleGallery: groq`
        
          layout,
          images[] {
              _key,
              image {
                  ${imageFragment}
              },
              "imageAlt": ${getIntlFieldFragment("imageAlt", locale)},
          },
          hidden
          `,

    moduleTextAccordion: groq`
          items[] {
              _key,
              "title": ${getIntlFieldFragment("title", locale)},
              "text": ${getBlockFragment("text", locale)},
          },
          hidden
          `,

    moduleImageAccordion: groq`
          items[] {
              _key,
              "title": ${getIntlFieldFragment("title", locale)},
              items[] {
                  _key,
                  "text": ${getBlockFragment("text", locale)},
                  image {
                      ${imageFragment}
                  }
              }
          },
          hidden
          `,

    moduleContentAccordion: groq`
          items[] {
              _key,
              "title": ${getIntlFieldFragment("title", locale)},
              items[] {
                  _key,
                  "title": ${getIntlFieldFragment("title", locale)},
                  items[] {
                      _key,
                      type,
                      "title": ${getIntlFieldFragment("title", locale)},
                      "text": ${getBlockFragment("text", locale)},
                      file {
                          ${fileFragment}
                      }
                  }
              }
          },
          hidden
          `,

    moduleProductAccordion: groq`
          items[] {
              _key,
              "title": ${getIntlFieldFragment("title", locale)},
              "text": ${getBlockFragment("text", locale)},
              image {
                  ${imageFragment}
              }
          },
          hidden
          `,

    moduleProjects: groq`
          "projects": *[_type == "project"][]{
              _id,
              "title": ${getIntlFieldFragment("title", locale)},
              type,
              slug,
              image {
                  ${imageFragment}
              },
          },
          pinned[]->{
              slug,
          },
          max,
          hidden
          `,

    moduleCTA: groq`
          "title": ${getIntlFieldFragment("title", locale)},
          "subtitle": ${getIntlFieldFragment("subtitle", locale)},
          style,
          link {
              ${getNavLinkFragment(locale)}
          },
          hidden
          `,
    moduleScript: groq`
          code,
          `,

    moduleTextImage: groq`
          "title": ${getIntlFieldFragment("title", locale)},
          "text": ${getIntlFieldFragment("text", locale)},
          image {
              ${imageFragment}
          },
          link {
              ${getNavLinkFragment(locale)}
          },
          hidden
        `,

    moduleTimeline: groq`
          "label": ${getIntlFieldFragment("label", locale)},
          items[] {
              title,
              "text": ${getIntlFieldFragment("text", locale)},
          },
          hidden
          `,

    moduleStatistics: groq`
          "label": ${getIntlFieldFragment("label", locale)},
          items[] {
              statistic,
              "subtitle": ${getIntlFieldFragment("subtitle", locale)},
          },
          hidden
          `,

    moduleQuote: groq`
          "jobtitle": ${getIntlFieldFragment("jobtitle", locale)},
          "client": ${getIntlFieldFragment("client", locale)},
          "quote": ${getBlockFragment("quote", locale)},
          hidden
          `,

    modulePeople: groq`
          label,
          items[] {
              "name": ${getIntlFieldFragment("name", locale)},
              "jobtitle": ${getIntlFieldFragment("jobtitle", locale)},
              "text": ${getIntlFieldFragment("text", locale)},
              image {
                  ${imageFragment}
              },
          },
          hidden
          `,

    moduleProcess: groq`
          image {
              ${imageFragment}
          },
          items[] {
              _key,
              "title": ${getIntlFieldFragment("title", locale)},
              "text": ${getIntlFieldFragment("text", locale)},
          },
          hidden
          `,
  }
}

function getPageModuleFragment(locale) {
  return {
    moduleHero: groq`
          "title": ${getIntlFieldFragment("title", locale)},
          "ctaText": ${getIntlFieldFragment("ctaText", locale)},
          media {
              ${mediaFragment}
          },
          hidden
          `,

    moduleText: groq`
          "title": ${getIntlFieldFragment("title", locale)},
          style,
          "label": ${getIntlFieldFragment("label", locale)},
          "body": ${getBlockFragment("body", locale)}[] {
              ...,
              markDefs[] {
                  ...,
                  _type == 'internalLink' => {
                      "slug": @.reference->slug
                  },
              }
          },
          hidden
          `,
    ...getDefaultModuleFragments(locale),
  }
}

function getProjectModuleFragment(locale) {
  return {
    moduleProjectText: groq`
          "title": ${getIntlFieldFragment("title", locale)},
          "body": ${getBlockFragment("body", locale)}[] {
              ...,
              markDefs[] {
                  ...,
                  _type == 'internalLink' => {
                      "slug": @.reference->slug
                  },
              }
          },
          hidden
          `,
    ...getDefaultModuleFragments(locale),
  }
}

const moduleListFragment = (fragment) =>
  Object.entries(fragment).reduce((query, [type, projection]) => {
    return groq`
        ${query}${query.length > 0 ? "," : ""}
        _type == '${type}' => {
            ${projection}
        }
    `
  }, ``)

function getPageFragment(locale) {
  return groq`
    _id,
    _key,
    _type,
    slug,
    "title": ${getIntlFieldFragment("title", locale)},
    heading,
    "description": ${getIntlFieldFragment("description", locale)},
    modules[] {
        _type,
        _key,
        ${moduleListFragment(getPageModuleFragment(locale))}
    }
`
}

function getProjectFragment(locale) {
  return groq`
    _id,
    _key,
    _type,
    slug,
    "title": ${getIntlFieldFragment("title", locale)},
    type,
    image{
        ${imageFragment}
    },
    "description": ${getBlockFragment("description", locale)},
    "architect": ${getIntlFieldFragment("architect", locale)},
    "location": ${getIntlFieldFragment("location", locale)},
    effect,
    modules[] {
        _type,
        _key,
        ${moduleListFragment(getProjectModuleFragment(locale))}
    },
    "otherProjects": *[_type == "project" && _id != ^._id][]{
        "title": ${getIntlFieldFragment("title", locale)},
        type,
        slug,
        image{
            ${imageFragment}
        },
    }
`
}

export function getCalculatorFragment(locale) {
  return groq`
    _id,
    _key,
    _type,
    slug,
    "title": ${getIntlFieldFragment("title", locale)},
    "estimateFootnote": ${getIntlFieldFragment("estimateFootnote", locale)},
    "footer": ${getBlockFragment("footer", locale)},
    priceRoundBy,
    installPrice,
    effectRoundBy,
    discounts[]{
        size,
        factor,
    },
    products {
        ...,
        "title": ${getIntlFieldFragment("title", locale)},
        "lead": ${getIntlFieldFragment("lead", locale)},
        "text": ${getIntlFieldFragment("text", locale)},
        products[]{
            ...,
            "slug": slug.current,
            "title": ${getIntlFieldFragment("title", locale)},
            "body": ${getBlockFragment("body", locale)},
            "installText": ${getIntlFieldFragment("installText", locale)},
            image {
                ${imageFragment}
            }
        }
    },
    details {
        ...,
        "title": ${getIntlFieldFragment("title", locale)},
        squared {
            "text": ${getIntlFieldFragment("text", locale)},
            active {
                "title": ${getIntlFieldFragment("title", locale)},
                "text": ${getIntlFieldFragment("text", locale)},
            },
            passive {
                "title": ${getIntlFieldFragment("title", locale)},
                "text": ${getIntlFieldFragment("text", locale)},
            }
        },
        length {
            "text": ${getIntlFieldFragment("text", locale)},
            active {
                "title": ${getIntlFieldFragment("title", locale)},
                "text": ${getIntlFieldFragment("text", locale)},
            },
            passive {
                "title": ${getIntlFieldFragment("title", locale)},
                "text": ${getIntlFieldFragment("text", locale)},
            }
        },
        location {
            ...,
            "title": ${getIntlFieldFragment("title", locale)},
            "text": ${getIntlFieldFragment("text", locale)},
            items[] {
                ...,
                "title": ${getIntlFieldFragment("title", locale)},
                countries[] {
                    ...,
                    "title": ${getIntlFieldFragment("title", locale)},
                    distributor-> {
                        ...,
                        "locationText": ${getIntlFieldFragment(
                          "locationText",
                          locale
                        )},
                    }
                }
            }
        }
    },
    options {
        ...,
        "title": ${getIntlFieldFragment("title", locale)},
        "text": ${getIntlFieldFragment("text", locale)},
        materials{
            ...,
            "title": ${getIntlFieldFragment("title", locale)},
            "text": ${getIntlFieldFragment("text", locale)},
            items[]{
                ...,
                "title": ${getIntlFieldFragment("title", locale)},
                "body": ${getBlockFragment("body", locale)},
                image {
                    ${imageFragment}
                }
            }
        },
        colors {
            ...,
            "title": ${getIntlFieldFragment("title", locale)},
            "text": ${getIntlFieldFragment("text", locale)},
            items[]{
                ...,
                "title": ${getIntlFieldFragment("title", locale)},
                "body": ${getBlockFragment("body", locale)},
                image {
                    ${imageFragment}
                }
            }
        }
    },
    submit {
        "title": ${getIntlFieldFragment("title", locale)},
        "text": ${getIntlFieldFragment("text", locale)},
        "receipt": ${getBlockFragment("receipt", locale)},
    }
`
}

export function getStringTranslationsFragment(locale) {
  return groq`
        translationGroups[] {
            key,
            translations[] {
                key,
                "value": ${getIntlFieldFragment("value", locale)},
            }
        }
    `
}

export const homeSlugQuery = groq`*[_type == 'siteSettings'][0].homepage->.slug.current`

export function getGlobalSettingsQuery(locale) {
  return groq`{
        ...,
        "title": ${getIntlFieldFragment("title", locale)},
        "header": *[_type == 'header' && _id == 'header'] {
            navigation[] {
                ${getNavLinkFragment(locale)}
            }
        }[0],
        "footer": *[_type == 'footer' && _id == 'footer'] {
            "title": ${getIntlFieldFragment("title", locale)},
            contact[] { 
                ...,
                offices[] {
                    "title": ${getIntlFieldFragment("title", locale)},
                    "address": ${getIntlFieldFragment("address", locale)},
                }
            },
            links[] {
                ${getNavLinkFragment(locale)}
            },
        }[0],
        "notificationsBar": *[_type == 'notificationsBar' && _id == 'notificationsBar'] {
            id,
            "notification": ${getBlockFragment("notification", locale)}[] {
                ...,
                markDefs[] {
                    ...,
                    _type == 'internalLink' => {
                        "slug": @.reference->slug
                    },
                },
            },
            enabled
        }[0],
       "downloadSettings": *[_type == 'downloadSettings'][0] {
            "downloadButtonLabel": ${getIntlFieldFragment(
              "downloadButtonLabel",
              locale
            )},
            "formConfirmationText": ${getIntlFieldFragment(
              "formConfirmationText",
              locale
            )},
            "textBeforeDownloadLinks": ${getIntlFieldFragment(
              "textBeforeDownloadLinks",
              locale
            )},
            "textAfterDownloadLinks": ${getIntlFieldFragment(
              "textAfterDownloadLinks",
              locale
            )},
       },
       "locales": *[_type == "locales"][0]{
            enabledLocales
       },
       "stringTranslations": *[_type == "stringTranslations"][0]{
            ${getStringTranslationsFragment(locale)}
        }
    }`
}

export function getFileGroupsFragment(locale) {
  return groq`*[_type == 'fileGroups'] | order(orderRank) {
        ...,
        "title": ${getIntlFieldFragment("title", locale)},
        files[] {
            ...,
            "title": ${getIntlFieldFragment("title", locale)},
            file {
                ${fileFragment}
            }
        }
    }`
}

export const getContentBySlugQuery = (
  mainBody,
  locale
) => groq`*[_type == 'siteSettings'][0]{
   "globalSettings": ${getGlobalSettingsQuery(locale)},
   "otherPages": * [$slug == slug.current] {
        _id,
        titel
   },
   "page": *[
        $slug == '/' && _id == ^.homepage._ref || slug.current == $slug &&
        ($skipProtection || protected != true) &&
        ((!defined(publishedAt) || $preview) || defined(publishedAt) && publishedAt < now())] | order(_updatedAt desc)[0] {
        _id,
        _type,
        "title": ${getIntlFieldFragment("title", locale)},
        "description": ${getIntlFieldFragment("description", locale)},
        "seoTitle": ${getIntlFieldFragment("seoTitle", locale)},
        "seoDescription": ${getIntlFieldFragment("seoDescription", locale)},
        seoImage {
            ${imageFragment}
        },
        ${mainBody}
   },
   "fileGroups": ${getFileGroupsFragment(locale)}
}`

export const draftQuery = groq`*[$slug == '/' && slug.current == ${homeSlugQuery} || slug.current == $slug]`

export const searchQuery = (
  start = 0,
  end = 10
) => groq`*[[title, description, pt::text(body)] match [$term, $term + "*", "*" + $term]] |
    score(
        title match $term,
        boost(pt::text(body) match $term, 1)
    ) | order(_score desc) {
        _score,
        _id,
        _type,
        title,
        description,
        subtitle,
        publishedAt,
        body,
        type,
        slug,
        image {
            ${imageFragment}
        },
    } [${start}...${end}]
`

export function getQueryMap(locale) {
  return {
    "/calculator*": groq`
        ${getCalculatorFragment(locale)}
    `,
    "/project/*": groq`
        ${getProjectFragment(locale)}
    `,
    "/*": groq`
        ${getPageFragment(locale)}
    `,
  }
}

export function getIntlFieldFragment(fieldName, lang) {
  return groq`coalesce(${fieldName}[_key == '${lang}'][0].value, ${fieldName}[_key == 'en'][0].value)`
}

export function getBlockFragment(fieldName, lang) {
  return groq`coalesce(${fieldName}[_key == '${lang}'][0].value.content, ${fieldName}[_key == 'en'][0].value.content)`
}
