import Accordion from "components/Accordion"

export default function ModuleContentAccordion({ module }) {
  const { items } = module
  if (items?.length === 0) return null

  return (
    <Accordion
      className="module-content-accordion container mt-16 mb-40"
      type="content"
      items={items}
    />
  )
}
