import { groq } from "next-sanity"
import Helmet from "../components/Helmet"
import resolvePageProps from "../lib/resolvePageProps"
import usePage from "../lib/usePage"
import { localeCodes } from "../locales.config.mjs"

export default function Page({
  data,
  preview,
  globalSettings,
  enabledLocales,
  fileGroups,
}) {
  const { TemplateComponent, page } = usePage({
    data,
    isPage: true,
  })

  return (
    <>
      <Helmet page={page} enabledLocales={enabledLocales} />
      <TemplateComponent
        key={page._id}
        page={page}
        preview={preview}
        globalSettings={globalSettings}
        enabledLocales={enabledLocales}
        fileGroups={fileGroups}
      />
    </>
  )
}

export async function getServerSideProps({
  params,
  preview = false,
  query: queryString,
  locale,
}) {
  try {
    const { globalSettings, fileGroups, page, query, queryParams, redirect } =
      await resolvePageProps({
        params,
        query: queryString,
        preview,
        locale,
      })

    if (redirect)
      return {
        redirect: {
          destination: redirect,
          permanent: true,
        },
      }

    // If the locale is not enabled, return a 404 page
    const enabledLocales = preview ? localeCodes : (
      globalSettings.locales?.enabledLocales || []
    ).concat(["en"])
    if (!page || !enabledLocales.includes(locale)) return { notFound: true }

    return {
      props: {
        // Pass down the "preview mode" boolean to the client-side
        preview,
        // Pass down the initial content, and our query
        globalSettings,
        enabledLocales,
        fileGroups,
        data: {
          page,
          query,
          queryParams,
          draftQuery: groq`*[$slug == '/' && slug.current == *[_id == 'siteSettings'][0].homepage->.slug.current || slug.current == $slug]`,
          draftParams: queryParams,
        },
      },
    }
  } catch (e) {
    console.log(e)
    return { notFound: true }
  }
}
