import React, { useState } from "react"
import { animate } from "framer-motion"
import { useEffect, useRef } from "react"

export default function Counter({ from = 0, to, duration = 0.5 }) {
  const nodeRef = useRef()
  const [previousTo, setPreviousTo] = useState(from)

  useEffect(() => {
    const node = nodeRef.current

    const controls = animate(previousTo, to, {
      duration,
      onUpdate(value) {
        node.textContent = Math.round(value).toLocaleString()
      },
    })
    setPreviousTo(to)

    return () => controls.stop()
  }, [to])

  return <span ref={nodeRef} />
}
